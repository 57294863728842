import React, {useEffect, useState} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import YouthlabContent from "./YouthlabContent";
import SliderComponents from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import NewButton from "../../components/NewButton/NewButton";
import python from "../../assets/python.png"
import axios from "../../axios";
import {useSelector} from "react-redux";
import Auth from "../Auth/Auth";
import SuccessModal from "../../components/Modal/SuccessModal";
 import { useTranslation } from "react-i18next"
const Youthlab = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const { token, user } = useSelector(state => state.auth)
    const [value, setValue] = useState('')
    const [youthlab, setYouthlab] = useState(null)
    const [course, setCourse] = useState(null)
    const [authModal, setAuthModal] = useState(false)
    const [success, setSuccess] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        fetchData()
        window.scrollTo(0, 0)
    }, [])


    const fetchData = async () => {
        const { data } = await axios.get('/laboratory/2')
        setYouthlab(data)
    }

    const handleClick = (course) => {
        if(token) {
            setCourse(course)
            toggleModal()
        } else {
            setAuthModal(true)
        }
    }
    const toggleModal = () => setModalOpen(!modalOpen)
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const newObj = {
                user: user.id,
                comment: value,
                course: course.id
            }
           await axios.post('/course-registration/', newObj)
            setSuccess(true)
        } catch (e) {
            alert(e.response.message)
        }
    }
    console.log(course)
    if(!youthlab) return null;
    return (
        <div className="youthlab">
            <HeaderContent bg={youthlab.image}>
                <div className="container container-sm">
                    <div className="title">{youthlab.title}</div>
                    <p className="p">{youthlab.description}</p>
                    {youthlab.rules && <div style={{color: "#fff", fontSize: "14px"}}>{youthlab.rules}</div>}
                </div>
            </HeaderContent>
            <div className="container container-sm" style={{marginTop: '100px'}}>
                <YouthlabContent />
            </div>
            {youthlab.courses.length > 0 && <SliderComponents getCourse={handleClick} courses={youthlab.courses}/>}
            <Footer />
            <Modal open={modalOpen} onClose={toggleModal}>
                <div className="event-modal">
                    <div className="event-modal-title">{t("el.elem38")} {course?.title}. {course?.start_date}</div>
                    <div className="event-modal-wrap custom-event-modal-wrap">
                        <div className="event-modal-left">

                            <div className="event-modal-desc">{t("el.elem21")}</div>
                            <form>
                                 <div className="textfield">
                                     <label htmlFor="value">
                                         <textarea value={value} onChange={({target: {value}}) => setValue(value)} />
                                     </label>
                                 </div>
                                <NewButton title={t("el.elem43")} onClick={handleSubmit} />
                            </form>
                        </div>
                        <div className="youthlab-img">

                            <img src={course?.image || python} alt=""/>

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}  startupDetails={() =>toggleModal()} />
            </Modal>
            <SuccessModal success={success} setSuccess={setSuccess} onClose={() => {
                setSuccess(false)
                toggleModal()
            }} />
        </div>
    );
};

export default Youthlab;
