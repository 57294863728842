import React, {useEffect, useState} from 'react'
import Burger from "../../components/Header/Burger";
import Logo from "../../components/Logo/Logo";
import PorfileImg from "./PorfileImg";
import Overlay from "../../components/Overlay/Overlay";
import Menu from "../../components/Header/Menu";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import instance from '../../axios';
import { useSelector } from 'react-redux';
import SuccessModal from "../../components/Modal/SuccessModal";
import {Redirect, useParams, useHistory} from "react-router-dom";
import {UserType} from "../../helpers/helpers";
import axios from "../../axios";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import { useTranslation } from "react-i18next"
import { ArrowBack, ArrowLeft , CloseRounded, CloudUpload} from '@material-ui/icons';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const editObj = {
    1: '/startup/',
    2: '/investor/',
    3: '/mentor/',
}
export const EditCardProfile = (props) => {
    const [open, setOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const spheres = useSelector(state => state.data.spheres)
    const [success, setSuccess] = useState(false)
    const { token, user } = useSelector(state => state.auth)
    const [form, setForm] = useState(null)
    const { id } = useParams()
    const { t } = useTranslation()
    const history = useHistory()
    const fetchObj = {
        1: '/user-startup/' + id,
        2: '/user-investor/',
        3: '/user-mentor/',
    }
    useEffect(() => {
        fetchCard()
    }, [])

    useEffect(() => {
        if(form?.file) {
            if(form && typeof form.file === 'object' &&  typeof form.file !== 'string' && form.file?.type !== "application/pdf")  {
                alert("Загрузка файла поддерживает только pdf")
                setForm({...form, file: ''})
            }
        }
    }, [form?.file])

    const fetchCard = async () => {
        try {
            const { data }  = await axios.get(fetchObj[user.user_type])
            setForm(user.user_type === 1 ? data : data[0])
        } catch (e) {
            console.log(e.message)
        }
    }


    const handleChangeForm = ({ target: {name, value} }) => setForm({...form, [name] : value})
    const handleClick  = async e => {
        e.preventDefault()
        try {
            console.log('form', form)
            await instance.put(editObj[user.user_type] + id + '/', {...form, file: null,  user: form.user.id,  sphere: form.sphere.map(x => x.id)})
            if(form.file && typeof form.file === 'object') {
                const formData = new FormData()
                formData.append("file", form.file)
                await instance.put(`/user-startup-file/${form.id}/`, formData)
            }
            setSuccess(true)
        } catch (e) {
            console.log(e.response)
            const data = Object.keys(e.response.data);
            console.log('data' ,data)
            NotificationManager.error(`${data[0]}: ${e.response.data[data[0]]}`)
        }
    }
    if(!token) {
        return <Redirect to={'/'} />
    }
    if(!form) return null;
    console.log(form);
  return(
      <div className="profile">
          <div className="container container-sm">
              <div className="profile-header">
                  <Burger onClick={() => setOpen(!open)} />
                  <Logo />
              </div>
              <div className="profile-top">
                  <dvi className="profile-top__arrow" onClick={() => history.goBack()}>
                      <ArrowBack />
                      <span>{t("el.elem94")}</span>
                  </dvi>
                  <div className="profile-title">{t("el.elem65")}</div>
                  <PorfileImg withoutEdit />
              </div>
              <div className="profile-content">
                  <form>
                      <Autocomplete
                          style={{marginBottom: "30px"}}
                          multiple
                          id="size-small-standard-multi"
                          size="small"
                          options={spheres}
                          getOptionLabel={(option) => option.name}
                          defaultValue={form.sphere.map(x => x)}
                          onChange={(e, newValue) => {
                              setForm({...form, sphere: newValue })
                          }}
                          renderOption={(option, {selected, value}) => {
                              return <React.Fragment>
                                  {option.name}
                              </React.Fragment>
                          }}
                          getOptionSelected={(option, selected) => {
                              return option.name === selected.name
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="standard"
                                  label={t("el.elem49")}
                              />
                          )}
                      />
                      {user.user_type === 1 && <div className="profile-field">
                          <label>{t("temp.name")}</label>
                          <input value={form.title} onChange={handleChangeForm} name="title"/>
                      </div>}
                      <div className="profile-field">
                          <label>{user.user_type === 1 ? t("el.elem90"): `Информация о ${UserType[user.user_type]}`}</label>
                          <textarea value={user.user_type === 1  ? form.idea : form.description} onChange={handleChangeForm} name={user.user_type === 1 ? 'idea' : "description"}  />
                      </div>
                      {user.user_type === 1 && <div className="profile-field">
                          <label>Необходимая сумма</label>
                          <input value={form.sum} onChange={handleChangeForm} name='sum' />
                      </div>}
                      {user.user_type === 1 && <div className="profile-upload profile-upload-presentation">
                                <label htmlFor="upload">
                                    {form.file ? <>
                                        <div>
                                            {typeof form.file === 'object' ? form.file.name : <a href={form.file} target='_blank'>{form.file}</a>}
                                             <span onClick={() => setForm({...form, file: ''})}><CloseRounded /></span>
                                        </div>
                                    </> :   <>
                                        {t("temp.upload")}
                                    <CloudUpload />
                                    </>}
                                    <input type="file"  style={{display: "none"}} onChange={(e) => setForm({...form, file: e.target.files[0]})} name="upload" id="upload"/>
                                </label>
                        </div>}
                      <div className="profile-role">
                          {UserType[user.user_type]}
                      </div>
                      <div className="profile-btn">
                          <button className='btnStyle' onClick={handleClick}>{t("el.elem72")}</button>
                      </div>
                  </form>
              </div>
          </div>
          <Overlay open={open} />
          <Menu open={open} onClick={() => setOpen(!open)}  />
          <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
              <Auth />
          </Modal>
          <SuccessModal
              title='Ваша карточка успешно отредактирована'
              description='Спасибо ваша карточка успешно отредактирована, перейдите в профиль чтобы посмотреть'
              setSuccess={setSuccess} onClose={() => setSuccess(false)} success={success} />
          <NotificationContainer/>

      </div>
   )
  }
