import {DONE, FAIL, FETCH, LOGIN_USER, LOGIN_USER_TOKEN, LOGOUT_USER} from "../constants";
import {createAction} from "redux-actions";
import {fetchFirstMe, fetchLoginRequest, fetchProfile} from "./auth.api";


const fetchLoginAction = createAction(LOGIN_USER + FETCH)
const fetchDoneLoginAction = createAction(LOGIN_USER + DONE)
const fetchFailLoginAction = createAction(LOGIN_USER + FAIL)


const getTokenAction = createAction(LOGIN_USER_TOKEN + DONE);
const logoutAction = createAction(LOGOUT_USER)
export const logout = () => dispatch => {
    localStorage.clear()
    dispatch(logoutAction())
}


export const loginUserThunk = (body, successLogin) => async dispatch => {
    dispatch(fetchLoginAction())
    try {
        const data = await fetchLoginRequest(body)
        const { id } = await fetchFirstMe(data.access)
        const userData = await fetchProfile(id)
        dispatch(fetchDoneLoginAction(userData))
        dispatch(getTokenAction(data.access))
         localStorage.setItem('refresh', data.refresh)
         localStorage.setItem('token', data.access)
         localStorage.setItem('user', JSON.stringify(userData))
        successLogin()
    } catch (e) {
        console.log('e', e.message)
        dispatch(fetchFailLoginAction(e.message))
    }
}
