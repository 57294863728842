import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, {
    Navigation
} from 'swiper';
import steamImg from "../../assets/steam.png"
SwiperCore.use([Navigation]);
const LessonSlider = ({ title, steam, images }) => {
    console.log('steam',images)
    return (
        <div className="container">
            <div className="lesson-slide-title">{title}</div>
            <Swiper    breakpoints={{
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }} navigation={true}   slidesPerView={4} loop={true}>
                {images.map(x => {
                    return (
                        <SwiperSlide>
                            <div className="lesson-slide">
                                <div className="lesson-slide__img">
                                    <img src={x.image || steamImg} alt=""/>
                                    {steam && x.el_version && <a href={x.el_version}>Электронная версия</a>}
                                </div>
                                <div>{x.name}</div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
};

export default LessonSlider;
