import React, {useEffect, useState} from 'react';
import {FacebookIcon, InstagramIcon, TelegramIcon, TwitterIcon, WhatsappIcon, YoutubeIcon} from "../icons/Icons";
import { useTranslation } from "react-i18next"
import axios from "../../axios"
const Footer = ({ marginTop }) => {
    const { t } = useTranslation()
    const [icons, setIcons] = useState([])
    useEffect(() => {
        fetchIcons()
    }, [])
    const fetchIcons = async () => {
        const { data } = await axios.get('/socials')
        setIcons(data)
    }

    return (
        <footer className="footer" style={{
            marginTop: marginTop ? marginTop: ''
        }}>
            <div className="footer__socials">
            {icons.map(x => <a href={x.link}>
                           <img src={x.icon} alt=""/>
                       </a>)}
            </div>
            <div className="footer__date">
                {t("el.elem12")} - {new Date().getFullYear()} © KyrgyzPatent
            </div>
        </footer>
    );
};

export default Footer;
