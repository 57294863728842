import React, {useState} from 'react';
import Login from "./Login";
import Register from "./Register";

const Auth = ({ startupDetails, onClose }) => {
    const [activeTab, setActiveTab] = useState(1);
    const contentObj = {
        1: <Login onClose={onClose} startupDetails={startupDetails} setActiveTab={setActiveTab} />,
        2: <Register setActiveTab={setActiveTab} />
    }
    return (
        <div className="auth">
            <form action="">
                {contentObj[activeTab]}
            </form>
        </div>
    );
};

export default Auth;
