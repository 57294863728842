import React, {useEffect, useState} from 'react';
import Burger from "../../components/Header/Burger";
import Logo from "../../components/Logo/Logo";
import Overlay from "../../components/Overlay/Overlay";
import Menu from "../../components/Header/Menu";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import PorfileImg from "./PorfileImg";
import axios from '../../axios';
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {UserType} from "../../helpers/helpers";
import { useTranslation } from "react-i18next"
import { logout } from '../../store/auth/auth.actions';
import { Redirect } from 'react-router-dom';
const Profile = () => {
    const [open, setOpen] = useState(false)
    const { user, token } = useSelector(state => state.auth)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const fetchObj = {
        1: '/user-startup/',
        2: '/user-investor/',
        3: '/user-mentor/',
    }
    const [cards, setCards] = useState([])
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get(fetchObj[user.user_type])
        setCards(data)
    }
    const [modalOpen, setModalOpen] = useState(false)
    const history = useHistory()
    if(!token) return <Redirect to="/" />
    const userTypeCheck = {
        1: t("")
    }
    return (
        <div className="profile">
            <div className="container container-sm">
                <div className="profile-header">
                    <div style={{display: "flex"}}>
                         <Burger onClick={() => setOpen(!open)} />
                         <div style={{marginLeft: "15px", fontSize:" 14px", color: "#fff"}} onClick={() => dispatch(logout())}>{t("temp.elem111")}</div>
                    </div>
                    <Logo />
                </div>
                <div className="profile-top">
                    <div className="profile-title">{t("el.elem65")}</div>
                    <PorfileImg />
                </div>
                {cards.length > 0 ? <>
                    {cards.map(x => (
                     <div className="my-cards">
                         <div className="my-cards__name">{x.title}</div>
                        {x.sphere.map(x => <div className="my-cards__title">{x.name}</div>)}
                        <div className="my-cards__desc">{user.user_type === 1 ? x.idea : x.description}</div>
                         {user.user_type === 1 && <div className="my-cards__sum">{t("el.elem87")}: {x.sum}$</div>}
                         {user.user_type === 1 && x.file && <div className="presentation-profile">
                                <a href={x.file} target="_blank">Презентация</a>
                            </div>}
                         <div className="my-cards__person">{UserType[user.user_type]}</div>
                        <button onClick={() => history.push('/profile/card/edit/' + x.id)}>{t('el.elem66')}</button>
                    </div>
                ))}
                </> : <div className="profile-empty">
                    <div className="profile-empty__title">{t("el.elem67")} {UserType[user.user_type]}а</div>
                    <div className="profile-empty__subtitle">Вы {UserType[user.user_type]}? {t("el.elem68").split('?')[1]}</div>
                    <div className="profile-empty__btn">
                        <button onClick={() => history.push('/profile/add/card')}>{t("el.elem69")}</button>
                    </div>
                </div>}
                {cards.length > 0 && user.user_type === 1 &&   <button className="btnStyleTransparent" onClick={() => history.push('/profile/add/card')}>{t("el.elem88")}</button>}
            </div>
            <Overlay open={open} />
            <Menu open={open} onClick={() => setOpen(!open)}  />
            <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
                <Auth />
            </Modal>
        </div>
    );
};

export default Profile;
