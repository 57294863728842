import React from 'react';

const NewDetailsContent = ({ data }) => {
    return (
        <div className="news-content">
            <div className="container container-sm">
                <div className="news-html"  dangerouslySetInnerHTML={{__html: data.body}} />
            </div>

        </div>
    );
};

export default NewDetailsContent;
