import React, {useEffect} from 'react';
import moderator from "../../assets/moderator.png"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from "@fullcalendar/core/locales/ru";
import isToday from "dayjs/plugin/isToday";
import {variableURL} from "../../axios";

dayjs.extend(isToday)
const EventContent = ({  handleClickModal, setEvent, event, events }) => {
    const { t } = useTranslation()
    const handleEventClick = (el) => {
        if(!dayjs().isBefore(el.event._instance.range.start)) {
            alert("Мероприятие уже прошло")
        } else {
            setEvent({...event, id: el.event.id, ...el.event._def.extendedProps})
            handleClickModal()
        }

    }
    return (
        <div className="event-content">
            <div className="title-event">{t("el.elem9")}</div>
            <FullCalendar
                locale={ruLocale}
               plugins={
                [
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  interactionPlugin // needed for dateClick
                ]
              }
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}

              slotMinTime= "07:00"
              slotMaxTime= "20:00"
              businessHours={ {
                startTime: '08:00', // a start time (10am in this example)
                endTime: '18:00', // an end time (6pm in this example)
              }}
              eventSources={
                [
                    `${variableURL}/events`
                ]
              }

              selectable={true}
              initialView='timeGridWeek'
              eventClick= {handleEventClick}
              height='auto'
      />
            {/* {events.map(x => (
                <div className="event-item">
                    <div className="event-left">
                        <span>{x.event_type.name}</span>
                        <div className="event-left-title">
                            {x.description}
                        </div>
                        <div className="event-date"><span>Когда</span>: {dayjs(x.date).locale('ru').format('MMMM D, YYYY')}</div>
                        <div className="event-slot">
                            <span>{t("el.elem22")}</span>: {x.address}
                        </div>
                        <div className="event-button" onClick={() => {
                            setEvent(x)
                            handleClickModal()
                        }}>
                            <button>{t("el.elem17")}</button>
                        </div>
                    </div>
                    <div className="event-right">
                        <img src={x.moderator.image || moderator} alt="moderator"/>
                        <div>
                            <span>Модератор</span>
                            <div>{x.moderator.first_name} <br/> {x.moderator.last_name}</div>
                        </div>
                    </div>
                </div>
            ))} */}
        </div>
    );
};

export default EventContent;
