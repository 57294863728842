import React, {useEffect, useState} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from '../../assets/ecosystem-bg.png'
import EcosystemContent from "./EcosystemContent";
import Footer from "../../components/Footer/Footer";
import axios from '../../axios';
import AppointmentModal from "../../components/Modal/AppointmentModal";
import {useSelector} from "react-redux";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
const Ecosystem = () => {
    const [data, setData] = useState({
        halls: [],
        lab: []
    })
    const [sectionData, setSectionData] = useState({});
    const [hallId, setId] = useState(null)
    const [open, setOpen] = useState(false)
    const [authModal, setAuthModal] = useState(false)
    const { token } = useSelector(state => state.auth)
    useEffect(() => {
        window.scrollTo(0,0)
        fetchData()
    }, [])
    const fetchData = async () => {
        const halls = await axios.get('/halls/')
        const lab = await axios.get('/laboratories/')
        const { data } = await axios.get('/sections/1/')
        setSectionData(data)
        setData({
            halls: halls.data,
            lab: lab.data
        })
    }
    const appointmentModal = id => {
        if(token) {
            setId(id)
            setOpen(true)
        } else {
            setAuthModal(true)
        }
    }
    return (
        <div className="ecosystem">
            <HeaderContent bg={sectionData.img || img}>
                <div className="container container-sm">
                    <div className="ecosystem-header">
                        <div>{sectionData.name}</div>
                    </div>
                    <div className="p">{sectionData.description}</div>
                </div>
            </HeaderContent>
           <div className="container container-sm">
               <EcosystemContent appointmentModal={appointmentModal} data={data}  />
           </div>
            <Footer marginTop={'100px'} />
            <AppointmentModal
                open={open}
                onClose={() => setOpen(false)}
                id={hallId}
            />
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}  startupDetails={() => setOpen(true)} />
            </Modal>
        </div>
    );
};

export default Ecosystem;
