import React, {useState} from 'react';
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { useTranslation } from "react-i18next"
import SuccessModal from "../../components/Modal/SuccessModal";
import {instanceWithoutApi} from "../../axios";
const ResetPassword = () => {
    const [value, setvalue] = useState('')
    const { t } = useTranslation()
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleClick = async () => {
        setLoading(true)
        try {
            await instanceWithoutApi.post('/auth/users/reset_password/', {email: value})
            setSuccess(true)
        } catch(e) {
            alert("Что то пошло не так...")
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="auth-login">
            <div className="auth-title">{t("el.elem98")}</div>
            <div className="auth-subtitle">{t("el.elem101")}</div>
            <TextFieldComponent
                title='Email'
                name='email'
                value={value}
                onChange={(e) => setvalue(e.target.value)}
            />
            <br/>
            <div className="auth-btn">
                <button disabled={loading} onClick={handleClick}>{loading ? '...' : t("temp.elem119")}</button>
            </div>
            <SuccessModal success={success} setSuccess={setSuccess} title={t("temp.elem120")} description={t("temp.elem121")}/>
        </div>
    );
};

export default ResetPassword;
