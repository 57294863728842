import axios from "../../axios";

const getSpehreAction = (payload) => ({
    type: "GET_SPHERES",
    payload
})






export const getSpheresThunk = () => async dispatch => {
    try {
        const { data } = await axios.get('/business-spheres/')
        dispatch(getSpehreAction(data))
    } catch (e) {
        console.log(e.message);
    }
}