import React, {useState, useEffect} from 'react';
import {Redirect, useHistory, useParams} from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next"
import {instanceWithoutApi} from "../../axios";
const UserActivation = () => {
    const history = useHistory()
    const {  token } = useParams()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchActivate()
    }, [])
    const { t } = useTranslation()
    const fetchActivate = async () => {
        try {
            await instanceWithoutApi.get(`/activate/${token}/`)
            setLoading(false)
        } catch(e) {
            alert("Что то пошло не так...")
            // history.push('/')
        }
    }
    if(!token) return <Redirect to='/' />
    return (
        <div className="success-activate">
            <div>
            <div className="success-activate-text">
                {loading ? "Подтверждение...." : t("temp.elem117")}
            </div>
                {!loading && <button disabled={loading} className="success-activate-btn"
                         onClick={() => history.push('/?activate')}>{t("temp.elem118")}</button>}
            </div>
        </div>
    );
};

export default UserActivation;
