import React from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core"
import dayjs from "dayjs"
const AdminTable1 = ({ data }) => {
    console.log('data', data)
    return (
        <TableContainer  >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>ФИО</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Контактный телефон</TableCell>
                        <TableCell align="right">Примечание</TableCell>
                        <TableCell align="right">Название мероприятия</TableCell>
                        <TableCell align="right">Дата мероприятия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((el) => (
                        <TableRow key={el.id}>
                            <TableCell component="th" scope="row">
                                {el.user.first_name + ' ' + el.user.last_name}
                            </TableCell>
                            <TableCell align="right">{el.user.email}</TableCell>
                            <TableCell align="right">{el.user.phone}</TableCell>
                            <TableCell align="right">{el.comment}</TableCell>
                            <TableCell align="right">{el.event.title}</TableCell>
                            <TableCell align="right">{dayjs(el.event.start).format('DD.MM.YYYY')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AdminTable1;
