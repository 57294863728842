import React from 'react';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {CloseIconModal} from "../icons/Icons";
import cn from "classnames";
const Modal = ({ title, open, width,  onClose, children }) => {
    return (
        <Dialog className={cn("dialog", {
            'customWidthModal': width
        })} open={open} onClose={onClose}>
            <div className="close-icon" onClick={onClose}>
                <CloseIconModal />
            </div>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default Modal;
