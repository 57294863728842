import React from 'react';
import peopleCard from "../../assets/peopleCard.png"
import {UserType} from "../../helpers/helpers";
import { useTranslation } from "react-i18next"
import defaultImage from "../../assets/default-avatar.png"
const PeopleCard = ({ img, title, onMouseOver, x, userType, link1, link2 }) => {
    const { t } = useTranslation()
    return (
        <div className="people-card">
            <div className="people-card-img" onMouseOver={() => onMouseOver && onMouseOver(x)}>
                <img src={img || defaultImage} alt=""/>
            </div>
            <div className="people-card-content">
                <div className="people-card-title">{title}</div>
                <div className="people-card__buttons">
                    <button onClick={link1}>{t("el.elem25")}</button>
                    <button onClick={link2}>{t("el.elem26")}</button>
                </div>
                <div className="user-type">{UserType[userType]}</div>
            </div>
        </div>
    );
};

export default PeopleCard;
