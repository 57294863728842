import { FlashOnTwoTone } from '@material-ui/icons';
import React, {useEffect, useRef, useState} from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import DialogModal from '../../components/Modal/DialogModal';
import { MapIcon } from '../../icons/Icons';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import {filteredCountry} from "../../helpers/helpers";
const chartColors = [
    "#336699",
    "#99CCFF",

  ];

const StartupChart = ({ dataModal }) => {
    const [data, setData] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [item, setItem] = useState({})
    const [regions, setRegions] = useState([])
    const ref = useRef();
    useEffect(() => {
        var ctx = document.querySelector('canvas').getContext("2d")
        var gradient = ctx.createLinearGradient(0, 0, 0, 400)
        gradient.addColorStop(0, 'rgba(229, 239, 255, 1)')
        gradient.addColorStop(1, 'rgba(229, 239, 255, 0.25)')
        const newData = {
            labels: ['Количество поданных заявок', 'Количество прошедших отборочный тур', 'Количество прошедших в финал', 'Количество финалистов'],
            datasets: [
                {
                    data: [302, 150, 350, 50],
                    backgroundColor: gradient,
                    borderColor: '#fff',
                    pointRadius: 0,
                    color: "#fff",
                    fillColor: "#fff",
                    strokeColor: "#fff",
                    highlightFill: "#fff",
                    highlightStroke: "#fff",
                }
            ]

        }

        setData(newData)
    }, [])
    useEffect(() => {
        if(item) {
            const arr = [
                {
                    count: 0,
                    district: "chyi"
                },
                {
                    count: 0,
                    district: "osh"
                },
                {
                    count: 0,
                    district: "issykkyl"
                },
                {
                    count: 0,
                    district: "talas"
                },
                {
                    count: 0,
                    district: "batken"
                },
                {
                    count: 0,
                    district: "naryn"
                },
                {
                    count: 0,
                    district: "jalalabad"
                },
            ]

            setRegions(filteredCountry((item.regions || []),  arr))
        }
    }, [item])

    const modalContentItem = {
        0: dataModal.counting_participants['Новый'],
        1: dataModal.counting_participants['Отобран'],
        2: dataModal.counting_participants['В финале'],
        3: dataModal.counting_participants['Финалист'],
    }

    const dataDoughnut = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["Мужчины", "Женщины"],
        datasets: [
          {
            data: [item.Male, item.Female],
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors
          }
        ]
      };
    const options = {
        maintainAspectRatio: true,
        responsive: true,
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data, temp3) {
                    if(!openModal) {
                        setOpenModal(true)
                        setItem(modalContentItem[tooltipItem.index])
                    }
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    label += Math.round(tooltipItem.yLabel * 100) / 100;

                    return label;
                }
            }
        },
        plugins: {
            datalabels: {
                display: true,
                color: "red"
            },
            beforeDraw: function(c) {
                var chartHeight = c.chart.height;
                var size = chartHeight * 5 / 100;
                c.scales['x-axis-0'].options.ticks.minor.fontSize = size;
             }
        },
        legend: {
            display: false,
            labels: {
                fontColor: "red",
                fontSize: 18
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontColor: "#fff",
                    fontSize: 18,
                    stepSize: 100,
                    min: 0,
                    max: 400,
                    beginAtZero: true
                },
                gridLines: {
                    display: false
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#fff",
                    fontSize: 14,
                    stepSize: 1,
                    beginAtZero: false,
                    display: window.innerWidth > 992,
                    color: "#fff",
                },
            }]
        }
    }
    const doughnutOptions = {
        legend: {
          display: true,
          position: "bottom"
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      };
    return (
        <div className="chart-wrapper">
            <Bar ref={ref} data={data} options={options} />
            <DialogModal onClose={() => setOpenModal(false)} open={openModal} chartClass="chart-modal">

                <MapIcon  regions={regions}/>
                {(item.Female !== 0 || item.Male !== 0) && <Doughnut data={dataDoughnut} options={doughnutOptions}/>}
                <div className="chart-modal__spheres">
                    Сфера деятельности
                </div>
                {(item.sphere || []).map(x => (
                    <div className="progress-wrap">
                        <div className="progress_name">{x.startup__sphere__name}</div>
                        <Progress percent={x.percent}  theme={{
                            success: {
                                color: '#1C4A47',
                                symbol: `${x.percent}%`
                            },
                            active: {
                                color: '#1C4A47',
                                symbol: `${x.percent}%`
                            },
                            default: {
                                color: '#1C4A47',
                                symbol: `${x.percent}%`
                            }
                        }} />
                    </div>
                ))}
            </DialogModal>
</div>
    );
};

export default StartupChart;
