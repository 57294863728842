import React from 'react';
import cn from "classnames"
const AdminSidebar = ({ activeIndex, setActiveIndex }) => {
    console.log('activeIndex', activeIndex)
    return (
        <div className="admin-sidebar">
            <ul>
                <li className={cn('',{
                    'active': activeIndex === 1
                })} onClick={() => setActiveIndex(1)}>
                    <div>Заявки на регистрацию
                        на мероприятия</div>
                </li>
                <li className={cn('',{
                    'active': activeIndex === 2
                })} onClick={() => setActiveIndex(2)}>
                    <div>Заявки на регистрацию
                        на обучающие курсы</div>
                </li>
                <li className={cn('',{
                    'active': activeIndex === 3
                })} onClick={() => setActiveIndex(3)}>
                    <div>Заявки на связь с
                        участником  экосистемы</div>
                </li>
                <li className={cn('',{
                    'active': activeIndex === 4
                })} onClick={() => setActiveIndex(4)}>
                    <div>Заявки на бронирование
                        рабочих зон</div>
                </li>
                <li className={cn('',{
                    'active': activeIndex === 5
                })} onClick={() => setActiveIndex(5)}>
                    <div>Регистрация пользователей</div>
                </li>
            </ul>
        </div>
    );
};

export default AdminSidebar;
