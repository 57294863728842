import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux"
import axios from "../../axios";
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from "../../assets/ecosystem-bg.png";
import LessonContent from "./LessonContent";
import {
    fetchSectionProgram,
    fetchEducationalProgram,
    fetchEventPassed,
    fetchEventComing,
    fetchSteamBooks, fetchSteamPartners, fetchSteamDatabase
} from './data.api';
import Pagination from '@material-ui/lab/Pagination';
import dayjs from "dayjs"
import { LessonPassedEvents } from './LessonPassedEvent';
import { EventComing } from './LessonComingEvent';
import Modal from "../../components/Modal/Modal";
import NewButton from "../../components/NewButton/NewButton";
import moder from "../../assets/moderator.png";
import Auth from "../Auth/Auth";
import LessonSlider from "./LessonSlider";
import Footer from "../../components/Footer/Footer";
import SuccessModal from "../../components/Modal/SuccessModal";
import { useTranslation } from "react-i18next"
const LessonProgram = () => {
    const { user, token } = useSelector(state => state.auth)
    const [sectionData, setSectionData] = useState({})
    const [lessons, setLessons] = useState([])
    const [eventPassed, setEventPassed] = useState([])
    const [eventPage, setEventPage] = useState(1);
    const [eventCount, setEventCount] = useState(0)
    const [eventComing, setEventComing] = useState([])
    const [eventComingPage, setEventComingPage] = useState(1);
    const [eventComingCount, setEventComingCount] = useState(0)
    const [value, setValue] = useState('');
    const [event, setEvent] = useState(null)
    const [openForm, setOpenForm] = useState(false)
    const [openAuth, setOpenAuth] = useState(false)
    const { t } = useTranslation()
    const [steamData, setSteamData] = useState({
        books: [],
        partners: [],
        database: [],
    })
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchEventsPassed()
    }, [eventPage])
    const fetchEventsPassed =  async () => {
        const data =  await fetchEventPassed(eventPage)
        setEventPassed(data.results)
        setEventCount(data.count)
    }

    useEffect(() => {
        fetchEventsComing()
    }, [eventComingPage])
    const fetchEventsComing =  async () => {
        const data =  await fetchEventComing(eventComingPage)
        setEventComing(data.results)
        setEventComingCount(data.count)
    }

    const fetchData = async () => {
        const [sectionData, lesson, books, partners, database] = await Promise.all([fetchSectionProgram(), fetchEducationalProgram(), fetchSteamBooks(), fetchSteamPartners(), fetchSteamDatabase()])
        setLessons(lesson)
        setSectionData(sectionData)
        setSteamData({
            books,
            partners,
            database
        })
    }

    const handleClickModal = (event) => {
        if(token) {
            setEvent(event)
            setOpenForm(true)
        } else {
            setOpenAuth(true)
        }
    }
    const handleSendData = async (e) => {
        e.preventDefault()
        try {
            const obj = {
                user: user.id,
                event: event.id,
                comment: value
            }
            await axios.post('/event-registration/', obj)
            setSuccess(true)
        } catch (e) {

        }
    }
    return (
        <div className="lesson">
            <HeaderContent bg={sectionData.img || img}>
                <div className="container container-sm">
                    <div className="ecosystem-header">
                        <div>{sectionData.name}</div>
                    </div>
                    <div className="p">{sectionData.description}</div>
                </div>
            </HeaderContent>
            <LessonContent arr={lessons} />
            {/* {eventPassed.length > 0 && <LessonPassedEvents events={eventPassed} /> }
            <Pagination className="pagination" count={Math.ceil(eventCount / 3)}   currentPage={eventPage} onChange={(e, val) => setEventPage(val)} />

            {eventComing.length > 0 && <EventComing events={eventComing} handleClickModal={handleClickModal} /> }
            <Pagination className="pagination" count={Math.ceil(eventComingCount / 3)}   currentPage={eventComingPage} onChange={(e, val) => setEventComingPage(val)} />
            <LessonSlider steam title='STEAM Book' images={steamData.books} />
            <LessonSlider title={t("el.elem32")} images={steamData.partners} />
            <LessonSlider title='STEAM Database' images={steamData.database} />
            {event && <Modal open={openForm} onClose={() => setOpenForm(false)}>
                <div className="event-modal">
                    <div className="event-modal-title">Зарегистрироваться на {event.event_type.name}</div>
                    <div className="event-modal-wrap">
                        <div className="event-modal-left">

                            <div className="event-modal-desc">Зарегистрируйтесь на данное мероприятие, чтобы <br/> Оставить заявку на бронирование место.</div>
                            <form>
                                <div className="textfield">
                                    <label htmlFor="comment">{t("el.elem21")}</label>
                                    <textarea name="comment" onChange={(e) => setValue(e.target.value)} value={value}  />
                                </div>
                                <div className="hidden-mobile">
                                    <NewButton title={t('el.elem43')} onClick={handleSendData}/>
                                </div>
                            </form>
                        </div>
                        <div className="event-modal-right">
                            <div className="event-modal-card">
                                <div className="event-modal-img">
                                    <img src={event.moderator.image || moder} alt=""/>
                                </div>
                                <span>{t("el.elem24")}</span>
                                <div className="event-modal-moder-name">{event.moderator.first_name + " " + event.moderator.last_name}</div>
                                <span>{event.event_type.name}</span>
                                <div className="event-modal-card-title">{event.description}</div>
                                <div className="event-date"><span>{t("el.elem21")}</span>: {dayjs(event.date).locale('ru').format('MMMM D, YYYY')}</div>
                                <div className="event-slot">
                                    <span>{t("el.elem22")}</span>: {event.address}
                                </div>
                            </div>
                        </div>
                        <div className="hidden-desktop">
                            <NewButton title={t("el.elem43")} />
                        </div>
                    </div>
                </div>
            </Modal>}
            <Modal width={true} open={openAuth} onClose={() => setOpenAuth(false)}>
                <Auth onClose={() => setOpenAuth(false)}  startupDetails={() => setOpenForm(true)} />
            </Modal>
            <SuccessModal success={success} onClose={() => setOpenForm(false)}  setSuccess={setSuccess}/> */}
            <Footer marginTop={100} />
        </div>
    );
};

export default LessonProgram;
