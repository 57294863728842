export const DONE = "_DONE";
export const FAIL = "_FAIL";
export const FETCH = "_FETCH";
export const FETCHING = "_FETCHING";
export const SET = "_SET";
export const FILE = "_FILE";
export const RESET = "_RESET";
export const META = "_META";
export const REMOVE = "_REMOVE";
export const LOADING = "_LOADING";


export const LOGIN_USER =  'auth/LOGIN_USER'
export const LOGIN_USER_TOKEN =  'auth/LOGIN_USER_TOKEN'
export const LOGOUT_USER = 'auth/LOGOUT_USER'
