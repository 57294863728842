import React, {useState, useEffect} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from "../../assets/ecosystem-bg.png";
import Button from "../../components/Button/Button";
import SliderComponents from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import 'react-calendar/dist/Calendar.css';
import AppointmentModal from "../../components/Modal/AppointmentModal";
import axios from "../../axios";
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import { useSelector } from "react-redux"
const Conference = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [form, setForm] = useState({
        fullName: "",
        email: "",
        phone: ""
    })
    const { token } = useSelector(state => state.auth);
    const [openAuth, setOpenAuth] = useState(false)
    const { t } = useTranslation()
    const [data, setData] = useState({})
    const [booked, setBooked] = useState(null)
    const toggleModal = () => token ? setModalOpen(!modalOpen) : setOpenAuth(true)
    useEffect(() =>  {
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
            const { data } = await axios.get('/hall-reservation-list/')
            const result = await axios.get('/hall/1')
            setData(result.data)
            setBooked(data.map(x =>dayjs( x.date_start).format('DD.MM.YYYY')))
        } catch (e) {
            console.log(e.message)
        }
    }

    return (
        <div className="conference">
            <HeaderContent bg={data.main_image || img}>
                <div className="container container-sm">
                    <div className="title">{data.title}</div>
                    <p>{data.description}</p>
                    <Button title={t("el.elem28")} onClick={toggleModal} />
                </div>
            </HeaderContent>
            <div className="conference__content p-100">
                <SliderComponents dinamyc={data.images} />
            </div>
            <Footer />
            <AppointmentModal
                booked={booked}
                onClose={toggleModal}
                open={modalOpen}
                form={form}
            />
            <Modal width={true} open={openAuth} onClose={() => setOpenAuth(false)}>
                <Auth onClose={() => setOpenAuth(false)}  startupDetails={() => setModalOpen(true)} />
            </Modal>
        </div>
    );
};

export default Conference;
