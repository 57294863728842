import React from 'react';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import cn from "classnames";
import {CloseIconModal} from "../icons/Icons";

const DialogModal = ({ open, applyClass, onClose, customClass, chartClass, title,  children }) => {
    return (
        <Dialog   className={cn("dialog-modal", {
            chartClass,
            customClass,
            applyClass
        })} open={open} onClose={onClose}>
            <div className="close-icon" onClick={onClose}>
                <CloseIconModal />
            </div>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default DialogModal;
