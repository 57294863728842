import React, { useEffect, useState } from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import StartupChart from "./StartupChart"
import Footer from "../../components/Footer/Footer";
import PeopleCard from "../../components/PeopleCard/PeopleCard";
import axios from "../../axios"
import {useHistory, useParams} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import DialogModal from "../../components/Modal/DialogModal";
import {BatkenIcon, ChuyIcon, IssykKulIcon, JalalAbadIcon, NarynIcon, OshIcon, TalasIcon} from "../../icons/Icons";
import StartupFeedback from "../../components/Modal/StartupFeedback";
const StartupDetail = () => {
    const [data, setData] = useState(null)
    const [finalists, setFinalists] = useState(null)
    const [finalistHover, setFinalistHover] = useState(true)
    const [finalistModalData, setFinalistModalData] = useState(null)
    const [startup, setStartup] = useState(null)
    const [call, setCall] = useState(false);
    const id = useParams().year;
    const history = useHistory()
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get('/startup-contest/' + id)
        setData(data)
        const result = await axios.get(`/startup-contestfinalists/${id}`)
        setFinalists(result.data)
    }
    const handleHover = item => {
        setFinalistModalData(item)
        setFinalistHover(true)
    }
    const regionsObj = {
        'chyi': {
            title: "Чуй",
            map: <ChuyIcon />
        },
        'osh': {
            title: "Ош",
            map: <OshIcon />
        },
        'issykkyl': {
            title: "Иссык-Куль",
            map: <IssykKulIcon />
        },
        'talas': {
            title: "Талас",
            map: <TalasIcon />
        },
        'batken': {
            title: "Баткен",
            map: <BatkenIcon />
        },
        'naryn': {
            title: "Нарын",
            map: <NarynIcon />
        },
        'jalalabad': {
            title: "Джалал-Абад",
            map: <JalalAbadIcon />
        },
    }
    const handleClickPerson = startup => {
        setStartup(startup)
        setCall(true)
    }
    return (
        <div className="startup">
            <HeaderContent bg={data?.image}>
                <div className="container container-sm">
                    <div className="title">
                        <div>{data?.due_date}</div>
                        {data?.name}
                    </div>
                    <p className="p">
                        {data?.description}
                    </p>
                    {data?.rules && <div className="p" style={{marginTop: "10px"}}>Правила: {data?.rules}</div>}
                </div>
            </HeaderContent>
            {data && <div className="startup__content">
                <StartupChart dataModal={data}/>
            </div>}
            <div className="container container-sm">
                <div className="startup__finalists hidden-mobile">
                    <div className="subtitle">Финалисты</div>
                    <div className="startup__finalists__wrap">
                        {(finalists || []).map(x => (
                               <>
                                   <PeopleCard
                                       onMouseOver={handleHover}
                                       x={x}
                                       link1={() => history.push(`/member/startup/${x.startup.id}`)}
                                       link2={() => handleClickPerson(x)}
                                       img={x.startup.user.image}
                                       title={x.startup.title}
                                   />
                               </>
                        ))}
                    </div>
                </div>
                <div className="hidden-desktop" style={{marginTop: "100px"}}>
                    <div className="subtitle">Финалисты</div>
                    <Swiper slidesPerView={1.2}>
                        {(finalists || []).map(x => (
                           <SwiperSlide>
                               <PeopleCard
                                   link1={() => history.push(`/member/startup/${x.startup.id}`)}
                                   link2={() => handleClickPerson(x)}
                                   img={x.startup.user.image}
                                   title={x.startup.title_en}
                               />
                           </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <Footer marginTop='100px' />
            {finalistModalData && <DialogModal chartClass open={finalistHover} onClose={() => setFinalistHover(false)}>
                <div style={{color: "#fff"}}>Область</div>
                <div className="finalist-modal-map">
                    {regionsObj[finalistModalData.district].map}
                    <div className="finalist-modal-map__title">{regionsObj[finalistModalData.district].title}</div>
                </div>
                <div style={{color: "#fff"}}>Пол: {finalistModalData.startup.user.gender === "M" ? "Мужской" : "Женский"}</div>
                {finalistModalData.startup.link && <div style={{color: "#fff"}}>Сайт: <a href={finalistModalData.startup.link}>{finalistModalData.startup.link}</a></div>}
            </DialogModal>}
            {startup && <StartupFeedback call={call} setCall={setCall} startup={startup.startup}/>}
        </div>
    );
};

export default StartupDetail;
