import React, {useEffect, useState} from 'react';
import TextFieldComponent, {SelectFieldComponent} from "../../components/TextField/TextFieldComponent";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import DialogModal from "../../components/Modal/DialogModal";
import * as yup from 'yup';
import instance from "../../axios";

const Register = ({ setActiveTab }) => {
    const [form, setForm] = useState({
        last_name: '',
        first_name: '',
        email: '',
        password: '',
        password2: '',
        phone: '',
        address: '',
        user_type: '1',
        gender: '',
        business_sphere: '1'
    })
    let schema = yup.object().shape({
        last_name: yup.string().required(),
        first_name: yup.string().required(),
        email: yup.string().required(),
        password: yup.string().required(),
        password2: yup.string().required(),
        phone: yup.string().required(),
        address: yup.string().required(),
        gender: yup.string().required(),
    });
    const { spheres } = useSelector(state => state.data)
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const [disabled, setDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const handleChangeFields = ({ target: { value, name } }) => setForm({...form, [name]: value})
    const handleSubmit =   async (e) => {
        e.preventDefault()
        setDisabled(true)
        schema.isValid(form).then(async x => {
            if(x) {
                try {
                    e.preventDefault();
                    await instance.post("/sign-up/", form)
                    setOpen(true)
                } catch (e) {
                    let error = Object.keys(e.response.data)[0]
                    setMessage(`${e.response.data[error]}`)
                    console.log(Object.keys(e.response.data));
                } finally {
                    setDisabled(false)
                }
            } else {
                setMessage('Заполните все поля!')
                setDisabled(false)
            }
        })
    }
    const onCLose = () => {
        setOpen(false)
        setTimeout(() => {
            setActiveTab(1)
        }, 1000)
    }
    return (
        <div className="auth-register">
            <div className="auth-title">{t("el.elem17")}</div>
            <div className="auth-subtitle">{t("el.elem104")} <span onClick={() => setActiveTab(1)}>{t("el.elem105")}</span></div>
            <TextFieldComponent
                title={t("el.elem74")}
                name='last_name'
                value={form.last_name}
                onChange={handleChangeFields}
            />
            <TextFieldComponent
                title={t("el.elem75")}
                name='first_name'
                value={form.first_name}
                onChange={handleChangeFields}
            />
            <TextFieldComponent
                title='Email'
                name='email'
                value={form.email}
                onChange={handleChangeFields}
            />
            <TextFieldComponent
                title={t("el.elem76")}
                name='phone'
                value={form.phone}
                onChange={handleChangeFields}
            />
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("el.elem55")}</FormLabel>
                <RadioGroup aria-label="gender" name="gender" onChange={handleChangeFields} value={form.gender}  >
                    <FormControlLabel value="M" control={<Radio />} label={t("el.elem106")} />
                    <FormControlLabel value="F" control={<Radio />} label={t("el.elem107")} />
                </RadioGroup>
            </FormControl>
            <TextFieldComponent
                title={t("el.elem77")}
                name='address'
                value={form.address}
                onChange={handleChangeFields}
            />
            <SelectFieldComponent
                name='business_sphere'
                title={t("el.elem49")}
                elements={spheres}
                value={form.business_sphere}
                onChange={handleChangeFields}
            />
            <TextFieldComponent
                title={t("el.elem80")}
                name='password'
                value={form.password}
                onChange={handleChangeFields}
            />
            <TextFieldComponent
                title='Подтвердите пароль'
                name='password2'
                value={form.password2}
                onChange={handleChangeFields}
            />
            <SelectFieldComponent
                name='user_type'
                title={t("el.elem79")}
                elements={[
                    {
                        title:     'Стартапер',
                        id: 1
                    },
                    {
                        title:     'Инвестор',
                        id: 2
                    },
                    {
                        title:     'Ментор',
                        id: 3
                    },
                    {
                        title:     'Пользователь рабочей зоны в ИЦ',
                        id: 4
                    },
                ]}
                value={form.user_type}
                onChange={handleChangeFields}
            />
            {message && <div style={{fontSize: "14px", color: "red", margin: "12px 0"}}>{message}</div>}
            <div className="auth-btn" style={{marginTop: "55px"}}>
                <button onClick={handleSubmit} disabled={disabled}>{disabled ? 'Загрузка...': t("el.elem108")}</button>
            </div>
            <div className="auth-copyright">
                <div className="auth-copyright__condition">
                    <div>{t("el.elem100")}</div>
                </div>
            </div>
            <DialogModal
                open={open}
                onClose={onCLose}
            >
                <div className="register-success">
                    <div>Мы отправили ссылку на почту</div>
                    <div>Перейдите по ней для активации</div>
                </div>
            </DialogModal>
        </div>
    );
};

export default Register;
