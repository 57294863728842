import React, {useState} from 'react';
import {Dialog} from "@material-ui/core";
import {CloseIconModal} from "../icons/Icons";
import peopleCard from "../../assets/peopleCard.png";
import {UserType} from "../../helpers/helpers";
import axios from "../../axios";
import {useSelector} from "react-redux";
import DialogModal from "./DialogModal";
import Modal from "./Modal";
import Auth from "../../pages/Auth/Auth";
import { useTranslation } from "react-i18next"
const StartupFeedback = ({ call, setCall, startup }) => {
    const [value, setValue] = useState('')
    const { token, user } = useSelector(state => state.auth)
    const [success, setSuccess] = useState(false)
    const [authModal, setAuthModal] = useState(false)
    const { t } = useTranslation()
    const handleSendData = async (e) => {
        e.preventDefault()
        if(!token) return setAuthModal(true)
        try {
            const newObj = {
                name: user.first_name,
                email: user.email,
                phone: user.phone,
                member_name : startup.user.first_name,
                comment: value
            }
            await axios.post('/contact-member/', newObj)
            setSuccess(true)
            setValue('')
        } catch (e) {

        }
    }
    return (
        <Dialog className="startup-feedback" open={call} onClose={() => setCall(false)}>
            <div onClick={() => setCall(false)}><CloseIconModal /></div>
            <div className="startup-feedback__profile">
                <img src={startup?.user.image ||  peopleCard} alt=""/>
                <div className="startup-detail__fullName">{startup?.user.first_name + ' ' +  startup?.user.last_name}</div>
                <span>{UserType[startup?.user.user_type]}</span>
            </div>
            <div className="startup-feedback__field">
                <div>{t("el.elem21")}</div>
                <textarea value={value} onChange={({target : {value}})  => setValue(value)} placeholder='Напишите...' />
                <div style={{textAlign: "center"}}>
                    <button disabled={token && !value} onClick={handleSendData} >{t("el.elem26")}</button>
                </div>
            </div>
            <DialogModal
                open={success}
                onClose={() => setSuccess(false)}
            >
                <div className="register-success">
                    <div>Ваш запрос отправлен успешно</div>
                </div>
            </DialogModal>
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}  startupDetails={() =>setAuthModal(false)} />
            </Modal>
        </Dialog>
    );
};

export default StartupFeedback;
