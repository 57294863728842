import React from 'react';
import {useHistory} from "react-router-dom";
import cn from "classnames";
const Button = ({ title, path, onClick, fill }) => {
    const history = useHistory()
    return (
        <div className={cn("button-wrapper", {
            'fill-btn': !!fill
        })}>
            <button onClick={onClick}>
                <span>{title}</span>
                <span />
            </button>
        </div>
    );
};

export default Button;
