import { handleActions } from 'redux-actions';
import {DONE, FAIL, FETCH, LOGIN_USER, LOGIN_USER_TOKEN, LOGOUT_USER} from "../constants";


export const initialState = {
    user:  JSON.parse(localStorage.getItem('user')) || {},
    loading: false,
    token:   localStorage.getItem('token') || '',
    error: ''
}



export default handleActions({
    [LOGIN_USER + FETCH]: state => ({
        ...state,
        loading: true
    }),
    [LOGIN_USER + DONE]: (state, { payload }) => ({
        ...state,
        loading: false,
        user: payload
    }),
    [LOGIN_USER_TOKEN + DONE]: (state, { payload }) => ({
        ...state,
        loading: false,
        token: payload
    }),
    [LOGIN_USER + FAIL]: (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload  }),
    [LOGOUT_USER]: (state) => ({
        ...state,
        token: '',
        user: {}
    })
}, initialState)



