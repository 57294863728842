import React, {useState} from 'react';
import { useSelector } from "react-redux";
import Sidebar from "../../Header/Sidebar";
import InfoBadge from "../../Header/InfoBadge";
import HeaderContent from "./HeaderContent";
import DialogModal from "../../Modal/DialogModal";
import Competition from "../../../pages/Competition/Competition";
import Modal from "../../Modal/Modal";
import Auth from "../../../pages/Auth/Auth";
import SuccessModal from "../../Modal/SuccessModal";
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom';
import mainBg from "../../../assets/mainbg.jpg"
const MainPageHeaderContent = ({ activeStartup, badgeInfo }) => {
    const [apply, setApply] = useState(false)
    const { token, user } = useSelector(state => state.auth)
    const [authModal, setAuthModal] = useState(false)
    const [message, setMessage] = useState(false)
    const { t } = useTranslation()
    const handleCLick = () => {
        if(user.user_type === 1) {
            setApply(true)
        } else {
            if(token) {
                setMessage(true)
            } else {
                setMessage(true)
                setAuthModal(true)
            }
        }
    }
    return (
        <>
            <HeaderContent bg={mainBg}>
                <div className="container">
                    <div className="content-header">
                            {activeStartup.length > 0 &&    <div className="header-content__title wow zoomIn">
                                    {t("temp.elem109")}
                                </div>}
                        <div className="header-content__btn">
                            {/* <button onClick={handleCLick}>{t("el.elem2")}</button> */}
                            <button onClick={() => window.open('https://my.matterport.com/show/?m=GtAr83mBQE8')}>Перейти</button>
                        </div>
                    </div>
                </div>
                <Sidebar />
                {badgeInfo && <div className="badge-wrap">
                    <div className="header-content__badge">
                        <InfoBadge badgeInfo={badgeInfo}/>
                    </div>
                </div>}
            </HeaderContent>
            <DialogModal applyClass customClass={true} open={apply} onClose={() => setApply(false)}>
                <Competition activeStartup={activeStartup} onClose={() => setApply(false)} />
            </DialogModal>
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}  startupDetails={() => user.user_type === 1 && setApply(true)} />
            </Modal>
            <SuccessModal success={message} setSuccess={setMessage}
                          title='Вы не стартапер'
                          description='Зарегистрируйтесь пожалуйста Стартапером'
            />
        </>
    );
};

export default MainPageHeaderContent;
