import React, { useState, useEffect } from 'react';
import {FacebookIcon, InstagramIcon, TelegramIcon, TwitterIcon, WhatsappIcon, YoutubeIcon} from "../icons/Icons";
import axios from "../../axios";

const Sidebar = () => {
    const [icons, setIcons] = useState([])
    useEffect(() => {
        fetchIcons()
    }, [])
    const fetchIcons = async () => {
        const { data } = await axios.get('/socials')
        setIcons(data)
    }
    return (
        <div className="header-sidebar">
           <div className="header-sidebar__wrap">
               <div className="header-sidebar__socials">
                   <ul>
                       {icons.map(x => <a href={x.link}>
                           <img src={x.icon} alt=""/>
                       </a>)}
                   </ul>
               </div>
           </div>
        </div>
    );
};

export default Sidebar;
