import React, {useEffect, useState} from 'react';
import Content from "./Content";
import MainPageHeaderContent from "../../components/pages/MainPage/Content";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios";
import {useLocation, useParams} from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";

const MainPage = () => {
    const [activeStartup, setActiveStartup] = useState([]);
    const [badgeInfo, setBadgeInfo] = useState(null);
    const { search } = useLocation();
    const [authModal, setAuthModal] = useState(false);
    const [gallerySlides, setGallerySlides] = useState(false);
    const [sections, setData] = useState({
        sects: []
    })
    const fetch = async () => {
        const { data } = await axios.get('/active-startup-contest/');
        const result = await axios.get('/events-near/')
        const gallery = await axios.get('/main-gallery/')
        
        const section = await axios.get('/sections/');
        setData({
            sects: section.data
        })
        //console.log(section.data);
/*        setSections({
            sects: section.data
        });*/
        setGallerySlides(gallery.data)
        setBadgeInfo(result.data)
        setActiveStartup(data)
    }
    useEffect(() => {
        fetch()
        if(search.includes('activate')) {
            setAuthModal(true)
        }
    }, [])
    return (
        <div className="main-page">
            <MainPageHeaderContent badgeInfo={badgeInfo} activeStartup={activeStartup} />
            <Content gallery={gallerySlides[0]?.images} sections={sections}/>
            <Footer />
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}    />
            </Modal>
        </div>
    );
};

export default MainPage;
