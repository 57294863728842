import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import NewButton from "../NewButton/NewButton";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
SwiperCore.use([Navigation]);
const SliderComponents = ({ content, dinamyc, getCourse, courses }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation()
    if(courses) {
        return (
            <Swiper
                navigation
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={(e) => setActiveIndex(e.activeIndex)}
                onSwiper={(swiper) => console.log(swiper)}
                className="swiper-wrap"
                centeredSlides={true}
                initialSlide={1}
                breakpoints={{
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    992: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    }
                }}
            >
                {courses.map(x => (
                    <SwiperSlide>
                        <div className="swiper-item courses-item" style={{background: `url(${x.image})`}}>
                            <div className="courses-item__wrap">
                                <div className="courses-item__date">Начало: {x.start_date}</div>
                                <div className="courses-item__title">{x.title}</div>
                                <div className="courses-item__desc">{x.description}</div>
                                <NewButton title={t("el.elem38")} onClick={() => getCourse(x)} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
    if(dinamyc) {
        return   <Swiper
            navigation
            spaceBetween={50}
            slidesPerView={3}
            onSlideChange={(e) => setActiveIndex(e.activeIndex)}
            onSwiper={(swiper) => console.log(swiper)}
            className="swiper-wrap"
            centeredSlides={true}
            initialSlide={1}
            breakpoints={{
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }}
        >
            {dinamyc.map(x => <SwiperSlide>
                <div className="swiper-item custom-swiper-item" style={{background: `url(${x.image})`}}>
                    <span>{x.name}</span>
                </div>
            </SwiperSlide>)}
        </Swiper>
    }
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={3}
            onSlideChange={(e) => setActiveIndex(e.activeIndex)}
            onSwiper={(swiper) => console.log(swiper)}
            className="swiper-wrap"
            centeredSlides={true}
            initialSlide={1}
            navigation
            breakpoints={{
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }}
        >
            <SwiperSlide>
                <div className="swiper-item">
                    <span>Фотографии с мероприятий</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="swiper-item">
                    <span>Фотографии с мероприятий</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="swiper-item">
                    {activeIndex === 1 && (content ||  <span>Фотографии с мероприятий</span>)}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="swiper-item">
                    <span>Фотографии с мероприятий</span>
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default SliderComponents;
