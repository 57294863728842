import React from 'react';
import {useTranslation} from 'react-i18next';

const YouthlabContent = () => {
    const { t } = useTranslation()
    return (
        <div className="youthlab-content">
            <div className="subtitle">{t("el.elem37")}</div>
        </div>
    );
};

export default YouthlabContent;
