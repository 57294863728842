import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation
} from 'swiper';
import "swiper/swiper.scss"

import photo from "../../assets/ecosystem1.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
SwiperCore.use([Navigation]);
const GalleryBottomSlider = ({ news }) => {
   const { t } = useTranslation()
    return (
        <div className="gallery-bottom__slider p-100">
            <h2>{t("el.elem14")}</h2>
            <Swiper navigation={true} slidesPerView={3} breakpoints={{
                992: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView:3
                },
                640: {
                    touchRatio: 1,
                    slidesPerView: 1
                },
                320: {
                    touchRatio: 1,
                    slidesPerView: 1
                }
            }}>
                {news.map(x => (
                    <SwiperSlide>
                        <Link to={`/news/detail/${x.id}`}>
                            <img src={x.main_image || photo} alt=""/>
                        </Link>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
};

export default GalleryBottomSlider;
