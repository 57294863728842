import React from 'react';
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const InfoBadge = ({ badgeInfo }) => {
    const { t } = useTranslation()
    return (
        <div className="header-badge">
            <div className="header-badge__date">
                {dayjs( badgeInfo[0]?.date).format('DD.MM.YYYY')}
            </div>
            <div className="header-badge__title">
                {badgeInfo[0]?.title}
            </div>
            <Link to='/events' className="header-badge__subtitle">
                {t("el.elem4")}
            </Link>
        </div>
    );
};

export default InfoBadge;
