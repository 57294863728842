import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import axios from "../../axios";
import NewDetailsContent from "./NewDetailsContent";
import './NewsDetail.scss'
import Footer from "../../components/Footer/Footer"
const NewDetails = () => {
    const [data, setData] = useState(null)
    const { id } = useParams()
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get(`/news/${id}/`)
        setData(data)
    }
    console.log('data', data)
    if(!data) return null
    return (
            <div className="news-page">
                <HeaderContent bg={data.main_image}>
                    <div className="container container-sm">
                        <div className="title">{data.title}</div>
                    </div>
                </HeaderContent>
                <NewDetailsContent data={data} />
                <Footer marginTop={100} />
            </div>
    );
};

export default NewDetails;
