import React, {useEffect, useMemo, useState} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from "../../assets/ecosystem-bg.png";
import EcosystemGalleryContent from "./EcosystemGalleryContent";
import GalleryBottomSlider from "./GalleryBottomSlider";
import GalleryMap from "./GalleryMap";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios";
import Pagination from "./pagination";
import {groupData} from "../../helpers/helpers";
import {useSelector} from "react-redux";
let PageSize = 8;
const EcosystemGallery = () => {
    const [ecosystems, setEcosystems] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [mapData, setMapData] = useState([])
    const [firstActiveCategory, setFirstActive] = useState(null)
    const [news, setNews] = useState([]);
    const [sectionData, setSectionData] = useState({});
    const [saveData, setSaveData] = useState([])
    const { spheres } = useSelector(state => state.data);
    const [sphere, setSphere] = useState()
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if(sphere) {
            const newArr = [...saveData];
            const filterArr = newArr.map(x => ({...x, data: x.data.filter(el => el.sphere.id === Number(sphere))})).filter(x => x.data.length);
            if(filterArr.length) {
                setFirstActive(filterArr[0].data.map(x =>  ({...x.location[0], color: x.type.color, name: x.name, sphereId: x.sphere.id})).flat())
                setMapData(filterArr)
            }
        }
    }, [sphere])
    // useEffect(() => {
    //     if(sphere) {
    //         setFirstActive(mapData[0].data.map(x =>  ({...x.location[0], color: x.type.color, name: x.name, sphereId: x.sphere.id})).flat())
    //     }
    // }, [mapData, sphere])
    const fetchData = async () => {
        const { data } = await axios.get('/ecosystems/')
        const result = await axios.get('/innovation-news/')
        const sectionData = await axios.get('/sections/2/')
        setSectionData(sectionData.data)
        setNews(result.data)
        setEcosystems(data)
        const grouped = groupData(data);
        setSaveData(grouped)
        setMapData(grouped)
        setFirstActive(grouped[0].data.map(x =>  ({...x.location[0], color: x.type.color, name: x.name, sphereId: x.sphere.id})).flat())
        console.log('Научные учреждения', data.filter(x => x.type.name === 'Научные учреждения'))
    }
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return ecosystems.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, ecosystems]);
    return (
        <div className="ecosystem-gallery">
            <HeaderContent bg={sectionData.img || img}>
                <div className="container container-sm">
                    <div className="ecosystem-header">
                       <div>{sectionData.name}</div>
                    </div>
                    <div className="p">{sectionData.description}</div>
                </div>
            </HeaderContent>
            <div className="container container-sm">
                <EcosystemGalleryContent arr={currentTableData} />
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={ecosystems.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />

                <GalleryBottomSlider  news={news} />
            </div>
            {firstActiveCategory && <GalleryMap sphere={sphere} setSphere={setSphere} spheres={spheres} mapData={mapData} setActive={setFirstActive} firstActiveCategory={firstActiveCategory}/>}
            <Footer />
        </div>
    );
};

export default EcosystemGallery;
