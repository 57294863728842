import React, {useState, useEffect} from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem} from "@material-ui/core"
import Switch from '@material-ui/core/Switch';
import { useSelector } from "react-redux"
import DialogModal from "../../components/Modal/DialogModal";
import {Edit} from "@material-ui/icons";
import axios from "../../axios";
import {UserType} from "../../helpers/helpers";
const AdminTable5 = ({ data }) => {
    const [item, setItem] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [statuses, setStatuses] = useState([])
    const [selectedStatus, setSelectedStatus] = useState({})
    const [newData, setNewData] = useState([])
    const { user } = useSelector(state => state.auth)
    useEffect(() => {
        if(typeof selectedStatus === 'number') {
            const newObj = statuses.find(x => x.id === selectedStatus)
            setSelectedStatus(newObj)
        }
    }, [selectedStatus])
    useEffect(() => {
        fetchData()
        setNewData(data.filter(x => x.id !== user.id))
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get('/participant-statuses/')
        setStatuses(data)
    }
    const handleClickSave = async () => {
        try {
            await axios.put(`/startup-contest-participant/${item.id}/`, {status: selectedStatus.id})
            const newArr = [...newData];
            const changeArr =  newArr.map(x => {
                if(x.id === item.id ) {
                   return {...x, status: selectedStatus}
                }
                return x;
            })
             setNewData(changeArr)
            setModalOpen(false)
        } catch (e) {
            console.log(e.message)
        }
    }
    const changeStatus = async (id, index) => {
        try {
            await axios.put(`/user-changestate/${id}/`, {is_active: !newData[index].is_active})
            const newArr = newData.map((el) => id === el.id ? ({...el, is_active: !el.is_active}) : el);
            setNewData(newArr)
        } catch (e) {
            console.log(e.message)
        }
    }
    return (
        <>
            <TableContainer  >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ФИО</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Контактный телефон</TableCell>
                            <TableCell align="right">Адрес</TableCell>
                            <TableCell align="right">Тип пользователя</TableCell>
                            <TableCell align="center">Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(newData || []).map((el, index) => (
                            <TableRow key={el.id}>
                                <TableCell component="th" scope="row">
                                    {el.first_name + ' ' + el.last_name}
                                </TableCell>
                                <TableCell align="right">{el.email}</TableCell>
                                <TableCell align="right">{el.phone}</TableCell>
                                <TableCell align="right">{el.address}</TableCell>
                                <TableCell align="right">{UserType[el.user_type]}</TableCell>
                                <TableCell align="right">
                                        <span className={el.is_active ? "active-user" : "not-active-user"}>{el.is_active ? 'Активный': "Не активный"}</span>
                                        <Switch checked={el.is_active} onChange={() => changeStatus(el.id, index)} color='primary'   />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {item && <DialogModal open={modalOpen} onClose={() => setModalOpen(false)} >
                <div className="change-status-modal">
                    <div>Изменить статус у {item.startup.user.first_name + ' ' + item.startup.user.last_name} </div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        defaultValue={item.status.id}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        {statuses.map(x => (
                            <MenuItem value={x.id}>{x.name}</MenuItem>
                        ))}
                    </Select>
                    <div className="change-status-modal__btn">
                        <button onClick={handleClickSave}>Сохранить</button>
                    </div>
                </div>
            </DialogModal>}
        </>
    );
};

export default AdminTable5;
