import React, {useState} from 'react';
import {ArrowDownIcon} from "../icons/Icons";
import cn from "classnames"
const DropDown = ({ item, content }) => {
    const [open , setOpen] = useState(false)
    return (
       <>
           <div onClick={() => setOpen(!open)} className={cn('menu-item', {
               'active': open
           })}>
               {item}
               <ArrowDownIcon />
           </div>
           {open && content}
       </>
    );
};

export default DropDown;
