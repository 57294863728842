import React, {useEffect, useState} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import FablabContent from "./FablabContent";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import NewButton from "../../components/NewButton/NewButton";
import modelphoto from "../../assets/model.png"
import axios from "../../axios";
import {useSelector} from "react-redux";
import Auth from "../Auth/Auth";
import SuccessModal from "../../components/Modal/SuccessModal";
import { useTranslation } from "react-i18next"
const Fablab = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [value, setValue] = useState('')
    const [fablab, setFablab] = useState(null);
    const { token, user } = useSelector(state => state.auth)
    const [course, setCourse] = useState(null)
    const [authModal, setAuthModal] = useState(false)
    const [success, setSuccess] = useState(false)
    const [equipments, setEquipments] = useState([])
    const { t } = useTranslation()
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get('/laboratory/1')
        const result = await axios.get('/equipments/')
        setEquipments(result.data)
        setFablab(data)
    }

    const toggleModal = () => setModalOpen(!modalOpen)
    const handleClick = (course) => {
        if(token) {
            setCourse(course)
            toggleModal()
        } else {
            setAuthModal(true)
        }
    }
    console.log('course', course)
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const newObj = {
                user: user.id,
                comment: value,
                course: course.id
            }
            await axios.post('/course-registration/', newObj)
            setSuccess(true)
        } catch (e) {
            alert(e.response.message)
        }
    }
    console.log('course', course)
    if(!fablab) return null;
    return (
        <div className="fablab">
            <HeaderContent bg={fablab.image}>
                <div className="container container-sm">
                    <div className="title">{fablab.title}</div>
                    <div className="p">{fablab.description}</div>
                    <p className="p">Условия допуска к Fablab: {fablab.rules}</p>
                 </div>
            </HeaderContent>
            <div className="container container-sm p-100">
                <FablabContent images={fablab.images} equipments={equipments} fablab={fablab} onClick={handleClick} />
            </div>
            <Footer />
            <Modal open={modalOpen} onClose={toggleModal}>
                <div className="event-modal">
                    <div className="event-modal-title">{t("el.elem38")} {course?.title}. {course?.start_date}</div>
                    <div className="event-modal-wrap custom-event-modal-wrap">
                        <div className="event-modal-left">

                            <div className="event-modal-desc">{t("el.elem21")}</div>
                            <form>
                                <div className="textfield">
                                    <label htmlFor="value">
                                        <textarea value={value} onChange={({target: {value}}) => setValue(value)} />
                                    </label>
                                </div>
                                <NewButton title={t("el.elem43")} onClick={handleSubmit}/>
                            </form>
                        </div>
                        <div className="fablab-img">

                            <img src={course?.image || modelphoto} alt=""/>

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal width={true} open={authModal} onClose={() => setAuthModal(false)}>
                <Auth onClose={() => setAuthModal(false)}  startupDetails={() =>toggleModal()} />
            </Modal>
            <SuccessModal success={success} setSuccess={setSuccess} onClose={() => {
                setSuccess(false)
                toggleModal()
            }} />
        </div>
    );
};

export default Fablab;
