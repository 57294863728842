import axios, {instanceWithoutApi} from "../../axios";



export const fetchLoginRequest = (body) => {
    return instanceWithoutApi.post("/auth/jwt/create", body).then(({ data }) => data)
}



export const fetchFirstMe = token => {
    return instanceWithoutApi.get('/auth/users/me', {
             headers: {
                 Authorization: 'Bearer ' + token
             }
    }).then(({ data }) => data)
}


export const fetchProfile = id => {
    return axios.get(`/user-edit/${id}`).then(({ data }) => data)
}
