import React from 'react';
import DialogModal from "./DialogModal";
import {  useTranslation } from "react-i18next"
const SuccessModal = ({ success, onClose, setSuccess, title, description }) => {
    const { t } = useTranslation()
    return (
        <DialogModal open={success} onClose={() => {
            setSuccess(false)
            onClose && onClose()
        }}>
            <div className="success-modal">
                <div>{title ? title: t("el.elem34")}</div>
                <p>{description ? description:  t("el.elem35")}</p>
            </div>
        </DialogModal>
    );
};

export default SuccessModal;
