import React, {useState} from 'react';
import Burger from "../../components/Header/Burger";
import Logo from "../../components/Logo/Logo";
import PorfileImg from "./PorfileImg";
import Overlay from "../../components/Overlay/Overlay";
import Menu from "../../components/Header/Menu";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import TextFieldComponent, {SelectFieldComponent} from "../../components/TextField/TextFieldComponent";
import {useDispatch, useSelector} from "react-redux";
import defaultAvatar from "../../assets/default-avatar.png";
import Button from "../../components/Button/Button";
import axios from "../../axios";
import DialogModal from "../../components/Modal/DialogModal";
import {SuccessIcon} from "../../components/icons/Icons";
import {logout} from "../../store/auth/auth.actions";
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next"
import { ArrowBack } from '@material-ui/icons';
const EditProfile = () => {
    const [open, setOpen] = useState(false)
    const user = useSelector(state => state.auth.user);
    const [modalOpen, setModalOpen] = useState(false)
    const [form, setForm] = useState({...user})
    const history = useHistory()
    const handleChange = ({ target: {value, name} }) => setForm({...form, [name]: value})
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const [preview, setPreview] = useState('')
    const { t } = useTranslation()
    const { spheres } = useSelector(state => state.data)
    const handleSubmitProfile = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        if(typeof form['image'] === 'string') {
            delete form['image']
        }
        const arrData = Object.keys(form);
        for(let item of arrData) {
            if(form[item]) {
                formData.append(item, form[item])
            }
        }
       const { data } =  await axios.put(`/user-edit/${user.id}/`, formData)
        localStorage.setItem('user', JSON.stringify(data))
        setSuccess(true)
    }
    const onCLose = () => {
        setSuccess(false)
    }
    const logoutApp = () => {
        dispatch(logout())
        history.push('/')
    }
   const handlePreview = (e) => {
        const file = e.target.files[0]
       const reader =   new FileReader()
       reader.onloadend = (e) => {
           setPreview(reader.result)
       }
       reader.readAsDataURL(file)
   }
    const imageHelper = () => {
        if(typeof form.image === 'string' && form.image) return form.image
        return preview || defaultAvatar
    }
    return (
        <div className="profile">
            <div className="container container-sm">
                <div className="profile-header">
                    <Burger onClick={() => setOpen(!open)} />
                    <Logo />
                </div>
                <div className="profile-top">
                    <div className="profile-title">{t("el.elem73")}</div>
                    <dvi className="profile-top__arrow" onClick={() => history.goBack()}>
                       <ArrowBack />
                      <span>{t("el.elem94")}</span>
                  </dvi>
                </div>
                <div className="profile-form">
                    <div className="profile-form__img">
                        {!user.image ?
                            <label htmlFor="upload" className='upload'>
                                <img src={imageHelper()} alt=""/>
                                <input type="file" name='upload' id='upload' onChange={(e) => {
                                    handlePreview(e)
                                    setForm({...form, image: e.target.files[0]})
                                }}
                                />
                            </label>  :     <label htmlFor="upload" className='upload'>
                                <img src={preview || user.image} alt=""/>
                                <input type="file" name='upload' id='upload' onChange={(e) => {
                                    handlePreview(e)
                                    setForm({...form, image: e.target.files[0]})
                                }}
                                />
                            </label> }
                    </div>
                    <form>
                        <TextFieldComponent name='last_name' value={form.last_name} onChange={handleChange}  title={t("el.elem74")} />
                        <TextFieldComponent name='first_name' value={form.first_name} onChange={handleChange}  title={t("el.elem75")}/>
                        <TextFieldComponent name='email' value={form.email} onChange={handleChange}  title='E-mail' />
                        <TextFieldComponent name='phone' value={form.phone} onChange={handleChange}  title={t("el.elem76")} />
                        <TextFieldComponent name='address' value={form.address} onChange={handleChange}  title={t("el.elem77")} />
                        <SelectFieldComponent
                            name='business_sphere'
                            title={t("el.elem49")}
                            elements={spheres}
                            value={form.business_sphere}
                            onChange={handleChange}
                        />
                        <TextFieldComponent
                            title={t("el.elem80")}
                            name='password'
                            value={form.password}
                            onChange={handleChange}
                        />
                        <Button title={t("el.elem72")} onClick={handleSubmitProfile} />
                    </form>
                    <div className="quit-app" onClick={logoutApp}>{t("el.elem82")}</div>
                </div>
            </div>
            <Overlay open={open} />
            <Menu open={open} onClick={() => setOpen(!open)}  />
            <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
                <Auth />
            </Modal>
            <DialogModal
                open={success}
                onClose={onCLose}
            >
               <div>
                   <div style={{textAlign: "center"}}><SuccessIcon /></div>
                   <div style={{color: "#fff"}}>Ваши измения сохранены</div>
               </div>
            </DialogModal>
        </div>
    );
};

export default EditProfile;
