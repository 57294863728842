import { combineReducers } from "redux";
import authReducers from "./auth/auth.reducers"
import dataReducers from "./data/data.reducers"
const rootReducers = combineReducers({
    auth: authReducers,
    data: dataReducers
})


export default rootReducers
