import React, { useEffect, useState } from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import StartupContent from "./StartupContent";
import Footer from "../../components/Footer/Footer";
import axios from '../../axios';

const Startup = () => {
    const [data, setData] = useState([])
    const [info, setInfo] = useState({})
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const {data} = await axios.get('/startup-contests/')
        const info = await axios.get('/sections/3/')
        setInfo(info.data)
        setData(data)
    }
    console.log('info', info)
    return (
        <div className="startup">
            <HeaderContent bg={info.img}>
               <div className="container container-sm">
                   <div className="title">{info.name}</div>
                   <p className="p">
                       {info.description}
                    </p>
               </div>
            </HeaderContent>
            <div className="container container-sm">
                <StartupContent data={data} />
            </div>
            <Footer marginTop='100px' />
        </div>
    );
};

export default Startup;
