import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from "../../assets/ecosystem-bg.png";
import axios from "../../axios";
import SliderComponents from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";

const EcosystemDetail = () => {
    const [data, setData] = useState(null)
    const { id } = useParams()
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get(`/ecosystem/${id}/`)
        setData(data)
    }
    if(!data) return null;
    return (
        <div>
            <HeaderContent bg={img}>
                <div className="container container-sm">
                    <div className="ecosystem-header">
                        <div>{data.name}</div>
                        <p className='p' style={{textAlign: "left"}}>{data.description}</p>
                    </div>
                </div>
            </HeaderContent>
            {data.images.length > 0 && <SliderComponents dinamyc={data.images}/>}
            <Footer />
        </div>
    );
};

export default EcosystemDetail;
