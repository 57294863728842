import React from 'react';

const Burger = ({ onClick }) => {
    return (
        <div className="header-sidebar-hamburger" onClick={onClick}>
           <div>
               <span></span>
               <span></span>
               <span></span>
           </div>
        </div>
    );
};

export default Burger;
