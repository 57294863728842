import React, {useState} from 'react';
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import {useDispatch, useSelector} from "react-redux";
import {loginUserThunk} from "../../store/auth/auth.actions";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ResetPassword from "./ResetPassword";
import Modal from "../../components/Modal/Modal";

const Login = ({ setActiveTab, onClose, startupDetails }) => {
    const [form, setForm] = useState({
        email: "",
        password: ''
    })
    const { error } = useSelector(state => state.auth)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const handleChange = ({ target : { value, name } }) => setForm({...form, [name]: value})
    const [resetOpen, setResetOpen] = useState(false)

    const successLogin =  () => {
        if(startupDetails) {
            onClose()
            setTimeout(() => {
                startupDetails()
            }, 1000)
        } else {
            history.push('/profile')
        }
    }

    const handleClick = async e => {
        e.preventDefault()
       try {
           dispatch(loginUserThunk(form, successLogin))
       } catch (e) {
            alert("Вы велли неправильные данные или же не авторизованы")
       }
    }
    console.log('error',error);
    return (
        <div className="auth-login">
            <div className="auth-title">{t("el.elem95")}</div>
            <div className="auth-subtitle">{t("el.elem96")}  <span onClick={() => setActiveTab(2)}>{t("el.elem97")}</span></div>
            <TextFieldComponent
                title='Email'
                name='email'
                value={form.email}
                onChange={handleChange}
            />
            <TextFieldComponent
                value={form.password}
                onChange={handleChange}
                title={t("el.elem80")}
                name='password'
            />
            <div className="auth-forgot" onClick={() => setResetOpen(true)}>{t("el.elem98")}</div>
            {error && <div className="auth-error" style={{margin: "15px 0", fontSize: "14px", color: "red"}}>Ваш профиль неактивный</div>}
            <div className="auth-btn">
                <button disabled={(!form.password || !form.email)} onClick={handleClick}>{t("el.elem99")}</button>
            </div>
            <div className="auth-copyright">
                <div className="auth-copyright__condition">
                    <div>{t("el.elem100")}</div>
                </div>
            </div>
            <Modal width open={resetOpen} onClose={() => setResetOpen(false)}>
                <ResetPassword />

                <div className="auth-copyright" />
            </Modal>
        </div>
    );
};

export default Login;
