import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from '@material-ui/lab/Pagination';
import './Admin.scss'
import AdminSidebar from "./AdminSidebar";
import AdminTable from "./AdminTable";
import axios from "../../axios";
const fetchObj = {
    1: '/event-registrations/',
    2: '/course-registrations/',
    3: '/contact-members/',
    4: '/hall-reservations/',
    5: '/user-get/',
}
const AdminPanel = () => {
    const [activeIndex, setActiveIndex] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const { user: { id } } = useSelector(state => state.auth)
    const [loadingTable, setLoadingTable] = useState(true)
    useEffect(() => {
        fetchUser()
    }, [])

    useEffect(() => {
        fetchData()
    }, [activeIndex, page])

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`${fetchObj[activeIndex]}?page=${page}`)
            console.log('wtf', data)
            setCount(data.count)
            setData(data.results)
        } catch (e) {

        }finally {
            setLoadingTable(false)
        }
    }
    const fetchUser = async () => {
       try {
           const { data } = await axios.get(`/user-edit/${id}`)
           setUser(data)
       } catch (e) {
           console.log('e', e.message)
       } finally {
           setLoading(false)
       }
    }
    const handleClickTabs = idx => {
        setActiveIndex(idx)
        setPage(1)
        setLoadingTable(true)
    }
    if(!loading && !user?.is_CRMManager) {
        return <Redirect to='/' />
    }
    if(loading) {
        return <div>Загрузка...</div>
    }

    return (
        <div className="admin">
            <div className="admin-wrap">
                <AdminSidebar setActiveIndex={handleClickTabs} activeIndex={activeIndex} />
                <div className="admin-content">
                    {loadingTable ? <CircularProgress /> : <AdminTable data={data} activeIndex={activeIndex} />}
                    <div className="admin-pagination">
                        <Pagination count={Math.ceil(count / 10)}   currentPage={page} onChange={(e, val) => setPage(val)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
