import React, {useState, useEffect} from 'react';
import NewButton from "../NewButton/NewButton";
import Calendar from "react-calendar";
import Modal from "./Modal";
import {useSelector} from "react-redux";
import axios from "../../axios";
import SuccessModal from "./SuccessModal";
import dayjs from "dayjs"
import { useTranslation } from "react-i18next";
import {useLocation} from "react-router-dom";
import helperTranslation from "../../hooks/useTranslation";
const AppointmentModal = ({   open, coworking,  booked,  id, onClose }) => {
    const { user } = useSelector(state => state.auth)
    const [value, setValue] = useState('')
    const [date, setDate] = useState(new Date())
    const [success, setSuccess] = useState(false)
    const [existBooked, setExistBooked] = useState([])
    const location = useLocation()
    const {t} = useTranslation()
    const locatioNobj = {
        '/conference': 1,
        '/coworking': 2
    }

    useEffect(() => {
        if(!booked) {
            fetchReservation()
        }
    }, [])
    const fetchReservation = async () => {
        const { data } = await axios.get('/hall-reservation-list/')
        setExistBooked(data.map(x =>dayjs( x.date_start).format('DD.MM.YYYY')))
    }
    const handleClick = async e => {
        e.preventDefault()
        try {
            const newObj = {
                user: user.id,
                comment: value,
                date_start: date,
                hall: locatioNobj[location.pathname] ? locatioNobj[location.pathname]  :  id
            }
            await axios.post('/hall-reservation/', newObj)
            setSuccess(true)
            setValue('')
            setDate(new Date())
        } catch (e) {
            alert(e.response.message)
        }
    }
    function tileDisabled({ date, view }) {
        if (view === 'month') {
            return (booked || existBooked).find(x => x === dayjs(date).format('DD.MM.YYYY'))
        }
    }
    return (
        <Modal open={open} onClose={onClose}>
            <div className="container container-sm">
                <div className="modal-title">{t("el.elem28")}</div>
                <div className="conference-modal-wrap">
                    <div className="conference-modal-form">
                        <form>
                            <div className='textfield'>
                                <label htmlFor="value">
                                    <textarea value={value} onChange={({target: {value}}) => setValue(value)}/>
                                </label>
                            </div>
                            <NewButton title={t("el.elem28")} onClick={handleClick} />
                        </form>
                    </div>
                    <div className="calendar-wrap">
                        <div className="calendar-info">
                            <div className="calendar-sum">
                                10
                            </div>
                            <span>Пятница</span>

                            <div className="not-booked">Не забронировано</div>
                        </div>
                        <Calendar
                            onChange={setDate}
                            value={date}
                            tileDisabled={coworking ? false : tileDisabled}
                        />
                    </div>
                </div>
            </div>
            <SuccessModal success={success} setSuccess={setSuccess} onClose={onClose} />
        </Modal>
    );
};

export default AppointmentModal;
