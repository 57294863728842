import React from 'react';
import { useTranslation } from "react-i18next"
const LessonContent = ({ arr }) => {
    const { t } = useTranslation()
    return (
        <div className="lesson-content">
            <div className="container container-sm">
            <div className="main-page__content">
            {arr.map((x, idx) => {
                if(idx % 2 + 1 === 1) {
                    return (
                        <div className="item variant-1">
                            <div className="container sm-container">
                                <div className="item-wrap">
                                    <div className="item-left">
                                        <div>{x.name}</div>
                                        <p>{x.description}</p>
                                        {x.link &&  <button onClick={() => window.open(x.link)} className="hidden-mobile">
                                        <span>{t("el.elem6")}</span>
                                        <span />
                                    </button>}
                                    </div>
                                    <div className="item-right wow fadeIn"  data-wow-delay="0.2s" data-wow-offset="50">
                                     <img src={x.image} alt=""/>
                                        <div className="item-right__blur"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="item variant-2">
                        <div className="container sm-container">
                            <div className="item-wrap">
                                <div className="item-right wow fadeIn"  data-wow-delay="0.2s" data-wow-offset="50">
                                    <img src={x.image} alt=""/>
                                    <div className="item-right__blur"></div>
                                </div>
                                <div className="item-left">
                                    <div>{x.name}</div>
                                    <p>{x.description}</p>
                                   {x.link &&  <button onClick={() => window.open(x.link)} className="hidden-mobile">
                                        <span>{t("el.elem6")}</span>
                                        <span />
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
            </div>
        </div>
    );
};

export default LessonContent;
