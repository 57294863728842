import React, {useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Burger from "../../components/Header/Burger";
import Logo from "../../components/Logo/Logo";
import PorfileImg from "./PorfileImg";
import Overlay from "../../components/Overlay/Overlay";
import Menu from "../../components/Header/Menu";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import instance from '../../axios';
import { useSelector } from 'react-redux';
import SuccessModal from "../../components/Modal/SuccessModal";
import { Redirect , useHistory} from "react-router-dom"
import {checkUserType, fetchCardType, UserType} from "../../helpers/helpers";
import { useTranslation } from "react-i18next"
import { CloseRounded, CloudUpload } from '@material-ui/icons';
const AddCards = () => {
    const [open, setOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const spheres = useSelector(state => state.data.spheres)
    const [success, setSuccess] = useState(false)
    const { token, user } = useSelector(state => state.auth)
    const history = useHistory()
    const [form, setForm] = useState({
        sphere: 1,
    })
    useEffect(() => {
        if(form.file) {
            if(form.file.type !== "application/pdf") {
                alert("Загрузка файла поддерживает только pdf")
                setForm({...form, file: ''})
            }
        }
    }, [form.file])
    const { t } = useTranslation()
    const handleChangeForm = ({ target: {name, value} }) => setForm({...form, [name] : value})
    const handleClick  = async e => {
        e.preventDefault()
        try {
            if(user.user_type === 1) {
              form['sum'] = Number(form.sum)
            }
            const keys = Object.keys(form);
            for(let item of keys) {
                if(!form[item]) {
                    return alert('Заполните все поля!')
                }
            }
           const {data: {id}} =  await instance.post(fetchCardType[user.user_type], {...form, file: null, sphere: form.sphere.map(x => x.id), user: user.id})
           if(form.file) {
            const formData = new FormData();
            formData.append("file", form.file);
            await instance.put(`/user-startup-file/${id}/`, formData)
           }
           setSuccess(true)
        } catch (e) {
            console.log(e.message);
        }
    }
    if(!token) {
        return <Redirect to={'/'} />
    }
     const descriptionType = {
        1: t("el.elem90"),
        2: t("temp.elem113"),
        3: t("el.elem71"),
        4: 'Пользователь рабочей зоны в ИЦ'
    }
    return (
        <div className="profile">
            <div className="container container-sm">
                <div className="profile-header">
                    <Burger onClick={() => setOpen(!open)} />
                    <Logo />
                </div>
                <div className="profile-top">
                    <div className="profile-title">{t("el.elem65")}</div>
                    <PorfileImg />
                </div>
                <div className="profile-content">
                    <form>
                        <div className="profile-field">
                            <label>{t("el.elem49")}</label>
                            {spheres.length > 0 && <Autocomplete
                                style={{marginBottom: "30px"}}
                                multiple
                                id="size-small-standard-multi"
                                size="small"
                                options={spheres}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, newValue) => {
                                    setForm({...form, sphere: newValue})
                                }}
                                renderOption={(option, {selected, value}) => {
                                    return <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                }}
                                getOptionSelected={(option, selected) => {
                                    return option.name === selected.name
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            />}
                        </div>

                        {user.user_type === 1  && <div className="profile-field">
                            <label>{t("temp.name")}</label>
                            <input onChange={handleChangeForm} name="title"/>
                        </div>}
                        <div className="profile-field">
                            <label>{descriptionType[user.user_type]}</label>
                            <textarea onChange={handleChangeForm} name={user.user_type === 1 ? "idea" : "description"}  />
                        </div>
                        {user.user_type === 1  && <div className="profile-field">
                            <label>{t("el.elem87")}</label>
                            <input onChange={handleChangeForm} name="sum"/>
                        </div>}
                        {user.user_type === 2  && <div className="profile-field">
                            <label>{t("temp.elem114")}</label>
                            <textarea className='investor-textarea' onChange={handleChangeForm} name="purpose"/>
                        </div>}
                        {user.user_type === 1 && <div className="profile-upload">
                                <label htmlFor="upload">
                                    {form.file ? <>
                                        <div>
                                        {form.file.name}
                                             <span onClick={() => setForm({...form, file: ''})}><CloseRounded /></span>
                                        </div>
                                    </> :   <>
                                        {t("temp.upload")}
                                    <CloudUpload />
                                    </>}
                                    <input type="file"  style={{display: "none"}} onChange={(e) => setForm({...form, file: e.target.files[0]})} name="upload" id="upload"/>
                                </label>
                            </div>}
                        <div className="profile-role">
                            {UserType[user.user_type]}
                        </div>
                        <div className="profile-btn">
                            <button className='btnStyle' onClick={handleClick}>{t("el.elem72")}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Overlay open={open} />
            <Menu open={open} onClick={() => setOpen(!open)}  />
            <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
                <Auth />
            </Modal>
            <SuccessModal
                title='Ваша карточка успешно создана'
                description='Спасибо ваша карточка успешно создана, перейдите в профиль чтобы посмотреть'
                setSuccess={setSuccess} onClose={() => setSuccess(false)} success={success}
                onClose={() => history.push('/profile')}
                />
        </div>
    )
};

export default AddCards;
