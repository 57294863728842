import { Switch, Route, Redirect, useLocation} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import Ecosystem from "./pages/Ecosystem/Ecosystem";
import EcosystemGallery from "./pages/Ecosystem/EcosystemGallery";
import Conference from "./pages/Conference/Conference";
import Fablab from "./pages/Fablab/Fablab";
import Startup from "./pages/Startup/Startup";
import Youthlab from "./pages/Youthlab/Youthlab";
import Event from "./pages/Event/Event"
import Portfolio from "./pages/Portfolio/Portfolio";
import Profile from "./pages/Profile/Profile";
import AddCards from "./pages/Profile/AddCards";
import StartupDetail from "./pages/Startup/StartupDetail";
import EditProfile from "./pages/Profile/EditProfile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {getSpheresThunk} from "./store/data/data.actions"
import StartupDetails from "./pages/StartupDetails/StartupDetails";
import EcosystemDetail from "./pages/Ecosystem/EcosystemDetail";
import NewDetails from "./pages/NewDetails/NewDetails";
import PageDetails from "./pages/Pages/PageDetails";
import {EditCardProfile} from "./pages/Profile/EditCard";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import LessonProgram from "./pages/LessonProgram/LessonProgram";
import Coworking from "./pages/Coworking/Coworking";
import {useTranslation} from "react-i18next";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import UserActivation from "./pages/UserActivation/UserActivation";
import WOW from "wowjs";

function App() {
  const dispatch = useDispatch();
  const { i18n,  } = useTranslation()
  const { pathname } = useLocation()
  useEffect(() => {
      const activeLang = localStorage.getItem('i18nextLng');
      if(activeLang) {
          const splited = activeLang.split("-");
          i18n.changeLanguage(splited[0])
      }
  }, [i18n])
  

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
}, [])

useEffect(() => {
  window.scrollTo(0, 0)
}, [pathname])

  useEffect(() => {
    dispatch(getSpheresThunk())
  }, [dispatch])
  return (
    <>
        <Switch>
            <Route exact path='/' component={MainPage} />
            <Route exact  path='/center' component={Ecosystem} />
            <Route exact  path='/ecosystem/detail/:id' component={EcosystemDetail} />
            <Route exact  path='/ecosystem' component={EcosystemGallery} />
            <Route exact  path='/conference' component={Conference} />
            <Route exact  path='/coworking' component={Coworking} />
            <Route exact  path='/fablab' component={Fablab} />
            <Route exact  path='/startup' component={Startup} />
            <Route exact  path='/member/:details/:id' component={StartupDetails} />
            <Route exact  path='/startup/:year' component={StartupDetail} />
            <Route exact  path='/youthlab' component={Youthlab} />
            <Route exact  path='/events' component={Event} />
            <Route exact  path='/portfolio' component={Portfolio} />
            <Route exact  path='/profile' component={Profile} />
            <Route exact  path='/profile/edit' component={EditProfile} />
            <Route exact  path='/profile/add/card' component={AddCards} />
            <Route exact  path='/profile/card/edit/:id' component={EditCardProfile} />
            <Route exact  path='/news/detail/:id' component={NewDetails} />
            <Route exact  path='/pages/:slug' component={PageDetails} />
            <Route exact path='/admin' component={AdminPanel} />
            <Route exact path='/LessonProgram' component={LessonProgram} />
            <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPassword} />
            <Route exact path='/activate/:token' component={UserActivation} />
            <Redirect to='/' />
        </Switch>
    </>
  );
}

export default App;
