import React from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core"
import { useTranslation } from "react-i18next"
const AdminTable3 = ({ data }) => {
    const { t } = useTranslation()
    return (
        <TableContainer  >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>ФИО</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">{t("el.elem76")}</TableCell>
                        <TableCell align="right">{t("el.elem21")}</TableCell>
                        <TableCell align="right">ФИО Участника</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((el) => (
                        <TableRow key={el.id}>
                            <TableCell component="th" scope="row">
                                {el.name}
                            </TableCell>
                            <TableCell align="right">{el.email}</TableCell>
                            <TableCell align="right">{el.phone}</TableCell>
                            <TableCell align="right">{el.comment}</TableCell>
                            <TableCell align="right">{el.member_name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AdminTable3;
