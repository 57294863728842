import axios from "../axios";

export const getInvestors = () => {
    return axios.get('/investors/').then(({ data }) => data)
}
export const getMentors = () => {
    return axios.get('/mentors/').then(({ data }) => data)
}
export const getStartups = () => {
    return axios.get('/startups/').then(({ data }) => data)
}


export const getMembers = (value) => {
    return axios.get(`/search/?q=${value ? value : ''}`).then(({ data }) => data)
}
