import React, {useEffect, useState} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import PortfolioContent from "./PortfolioContent";
import {getInvestors, getMembers, getMentors, getStartups} from "../../utils/api.data";
import StartupFeedback from "../../components/Modal/StartupFeedback";
import axios from "../../axios";
import Footer from "../../components/Footer/Footer";
import {useDebounce} from "../../hooks/useDebounce";

const Portfolio = () => {
    const [data, setData] = useState({
        investors: [],
        mentors: [],
        startups: []
    })
    const [call, setCall] = useState(false);
    const [startup, setStartup] = useState(null)
    const [sectionData, setSectionData] = useState({});
    const [value, setValue] = useState('')
    const searchValue = useDebounce(value, 500)
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        fetchMembers()
    }, [searchValue])
    const fetchMembers = async () => {
        const { investors, mentors, startups } = await getMembers(searchValue)
        setData({
            investors,
            mentors,
            startups
        })
    }
    const fetchData = async () => {
        const res = await getMembers()
        console.log('res',res)
        const  { data } = await axios.get('/sections/5')
        setSectionData(data)
    }
    const handleClickPerson = startup => {
        setStartup(startup)
        setCall(true)
    }
    console.log('searchValue', searchValue)
    return (
        <section className="portfolio">
            <HeaderContent bg={sectionData.img}>
                <div className="container container-sm">
                    <div className="title">{sectionData.name}</div>
                    <p className="p">{sectionData.description}</p>
                 </div>
            </HeaderContent>
            <div className="container container-sm">
                <PortfolioContent
                    call={call}
                    setCall={setCall}
                    handleClickPerson={handleClickPerson}
                    data={data}
                    setValue={setValue}
                    value={value}
                />
            </div>
            <StartupFeedback call={call} setCall={setCall} startup={startup} />
            <Footer marginTop={100} />
        </section>
    );
};

export default Portfolio;
