import React, {useEffect, useState} from 'react';
import TextFieldComponent, {SelectFieldComponent} from "../../components/TextField/TextFieldComponent";
import axios from "../../axios";
import SuccessModal from "../../components/Modal/SuccessModal";
import {useTranslation} from "react-i18next";

const Competition = ({ activeStartup, onClose }) => {
    const [form, setForm]  = useState({
        startup: '',
        file: '',
        startup_contest: activeStartup.length > 0 ? activeStartup[0].id: '',
        district: 'chuy'
    })
    const { t } = useTranslation()
    const [startups, setStartaps] = useState([]);
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState(false)
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get('/user-startup/')
        if(data.length) setForm({...form, startup: data[0].id})
        setStartaps(data)
    }
    const handleChange = ({ target: { name, value } }) => {
        setForm({...form, [name]: value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!startups.length) return setMessage(true)
        try {
            const formData = new FormData();
            const keys = Object.keys(form);
            for(let item of keys) {
                formData.append(item, form[item])
            }
            await axios.post('/startup-contest-participant/', formData)
            setSuccess(true)
        } catch (e) {
            alert('Что то пошло не так, убедитесь что все поля заполнены')
        }
    }
    const countriesData = [
        {
            id: "chyi",
            title: 'Чуйская область'
        },
        {
            id: "osh",
            title: 'Ошская область'
        },
        {
            id: "issykkyl",
            title: 'Иссык-Кульская область'
        },
        {
            id: "talas",
            title: 'Таласская область'
        },
        {
            id: "batken",
            title: 'Баткенская область'
        },
        {
            id: "naryn",
            title: 'Нарынская область'
        },
        {
            id: "jalalabad",
            title: 'Джалал-абадская область'
        },
    ]
    return (
        <div className="competition">
            <div className="competition-title">{t("el.elem46")}</div>
            <div className="competition-desc">{t("el.elem47")}</div>
            <form className="competition-form">
                <SelectFieldComponent
                    name='district'
                    title='Город'
                    elements={countriesData}
                    value={form.district}
                    onChange={handleChange}
                />
                <SelectFieldComponent
                    name='startup'
                    title='Стартапы'
                    elements={startups}
                    value={form.startup}
                    onChange={handleChange}
                />
                <div>
                    <label htmlFor="upload">
                        <span className="selected-file">
                            {t("el.elem48")}
                        </span>
                        <div className="upload-startup-modal">
                            <span>{form.file ? form.file.name : 'Загрузите файл'}</span>
                            <input type='file' name='upload' id='upload'  style={{display: "none"}} onChange={(e) => setForm({...form, file: e.target.files[0]})} />
                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.08116 9.49979L8.24642 2.36839C9.73039 0.891425 12.1364 0.891425 13.6204 2.36839C15.1043 3.84535 15.1043 6.23997 13.6204 7.71694L5.11162 16.1855C4.36963 16.924 3.16663 16.924 2.42464 16.1855C1.68265 15.447 1.68265 14.2497 2.42464 13.5112L10.0377 5.93409C10.2851 5.68793 10.2851 5.28882 10.0377 5.04266C9.79041 4.7965 9.38941 4.7965 9.14208 5.04266L1.52898 12.6198C0.292338 13.8506 0.292338 15.8461 1.52898 17.0769C2.76563 18.3077 4.77063 18.3077 6.00727 17.0769L14.516 8.60836C16.4947 6.63908 16.4947 3.44624 14.516 1.47696C12.5374 -0.49232 9.32939 -0.49232 7.35076 1.47696L0.185497 8.60836C-0.0618323 8.85452 -0.0618323 9.25362 0.185497 9.49979C0.432826 9.74595 0.833826 9.74595 1.08116 9.49979Z" fill="#C4C4C4"/>
                            </svg>
                        </div>
                    </label>
                </div>
                <div style={{textAlign: "center"}}>
                    <button onClick={handleSubmit}>{t("el.elem43")}</button>
                </div>
            </form>
            <SuccessModal setSuccess={setSuccess} success={success} onClose={onClose} />
            <SuccessModal setSuccess={setMessage} success={message} title='Стартап идея отсуствует' description='Создайте стартап идею в личном кабинете' />
        </div>
    );
};

export default Competition;
