import React, { useEffect, useState } from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button} from "@material-ui/core"
import dayjs from "dayjs"
import axios from "../../axios"
const AdminTable4 = ({ data }) => {
    const [newData, setNewData] = useState([]);

    useEffect(() => {
        if(data) {
            setNewData(data)
        }
    }, [data])
    console.log('data', data)
    const toggleStatus = async (el, idx) => {
        try {
            await axios.put(`/hall-reservation_confirm/${el.id}/`, {is_confirmed: !newData[idx].is_confirmed})
            const newArr = newData.map((x, index) => idx === index ? ({...x, is_confirmed: !x.is_confirmed}): x);
            setNewData(newArr)
        } catch(e) {
            console.log(e.message);
        }
    }
    return (
        <TableContainer  >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>ФИО</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Контактный телефон</TableCell>
                        <TableCell align="right">Примечание</TableCell>
                        <TableCell align="right">Название Рабочей зоны</TableCell>
                        <TableCell align="right">Дата и время начало</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newData.map((el, idx) => (
                        <TableRow key={el.id}>
                            <TableCell component="th" scope="row">
                                {el.user.first_name + ' ' + el.user.last_name}
                            </TableCell>
                            <TableCell align="right">{el.user.email}</TableCell>
                            <TableCell align="right">{el.user.phone}</TableCell>
                            <TableCell align="right">{el.comment}</TableCell>
                            <TableCell align="right">
                                <div className="confirmed-table">
                                    <span>{el.hall.title} </span>
                                    {el.hall.is_hall && <Button onClick={() => toggleStatus(el, idx)} color={el.is_confirmed ? "secondary" : "primary"} variant="contained">{el.is_confirmed ? "Отменить": "Подтвердить"}</Button>}
                                </div>
                            </TableCell>
                            <TableCell align="right">{dayjs(el.date).format('DD.MM.YYYY')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AdminTable4;
