import React from 'react';
import {useSelector} from "react-redux";
import defaultAvatar from "../../assets/default-avatar.png";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
const PorfileImg = ({ withoutEdit }) => {
    const user = useSelector(state => state.auth.user);
    const { t } = useTranslation()
    return (
        <div className="profile-img">
            {!user.image ?
                <img src={defaultAvatar} alt=""/>  : <img src={user.image} alt="User"/>}
            <div>
                <div>{ user.first_name + ' ' +  user.last_name }</div>
                {!withoutEdit && <Link to='/profile/edit'>{t("el.elem66")}</Link>}
            </div>
        </div>
    );
};

export default PorfileImg;
