import React from 'react';



export const  SelectFieldComponent = ({ name, title, value, type, onChange, elements }) => {
    return (
        <div className="textfield">
            <label htmlFor={name}>{title}</label>
            <select  name={name} id={name} onChange={onChange} placeholder={title} value={value}>
                {elements.map(x => <option value={x.id}>{x.title || x.name}</option>)}
            </select>
        </div>
    )
}


const TextFieldComponent = ({ name, title, value, type, onChange }) => {
    const helperType = () => {
        const obj = {
            'email': true,
            'password': true
        }
        if(obj[name]) return name
        return 'text'
    }
    return (
        <div className="textfield">
            <label htmlFor={name}>{title}</label>
            <input type={name === 'password2' ? 'password' : helperType()} name={name} id={name} onChange={onChange} placeholder={title} value={value}/>
        </div>
    );
};

export default TextFieldComponent;
