import React from 'react';
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next"
import {useHistory} from "react-router-dom";
const EcosystemContent = ({ data , appointmentModal}) => {
    const history = useHistory()
    const { t } = useTranslation()
    if(!data.halls.length  && !data.lab.length) return <div style={{textAlign: "center", marginTop: "20px", color: "#fff"}}>Данных пока нет</div>;
    return (
        <>
            <div className='ecosystem-content p-100'>
                <div className="ecosystem__left">
                    <div className="ecosystem__title">{data.halls[0].title}</div>
                    <div className="ecosystem__desc">{data.halls[0].description}</div>
                    <div className="ecosystem__buttons">
                        <Button title={t("el.elem28")} fill={true}  onClick={() => appointmentModal(data.halls[0].id)}  />
                        <Button title={t("el.elem27")}  onClick={() => history.push('/conference')} />
                    </div>
                </div>
                <div className="ecosystem__right">
                    <img className="img" src={data.halls[0].main_image} alt=""/>
                </div>
            </div>
            <div className='ecosystem-content p-100'>
                <div className="ecosystem__left-img">
                    <img className="img" src={data.halls[1].main_image} alt=""/>
                </div>
                <div className="ecosystem__right-title">
                    <div className="ecosystem__title">{data.halls[1].title}</div>
                    <div className="ecosystem__desc">{data.halls[1].description}</div>
                    <div className="ecosystem__buttons">
                        <Button title={t("el.elem28")}   onClick={() => window.open('https://forms.gle/isZiPRrsLNZ1rMDVA')}  />
                        <Button title={t("el.elem27")}  onClick={() => history.push('/coworking')} />
                    </div>
                </div>
            </div>
            <div className='ecosystem-content p-100'>
                <div className="ecosystem__left">
                <div className="ecosystem__title">{data.lab[0].title}</div>
                <div className="ecosystem__desc">{data.lab[0].description}</div>
                    <div className="ecosystem__buttons">
                        <Button title={t("el.elem27")}  onClick={() => history.push(`/fablab`)} />
                    </div>
                </div>
                <div className="ecosystem__right">
                    <img className="img" src={data.lab[0].image} alt=""/>
                </div>
            </div>
            <div className='ecosystem-content p-100'>
                <div className="ecosystem__left-img">
                    <img className="img" src={data.lab[1].image} alt=""/>
                </div>
                <div className="ecosystem__right-title">
                <div className="ecosystem__title">{data.lab[1].title}</div>
                <div className="ecosystem__desc">{data.lab[1].description}</div>
                    <div className="ecosystem__buttons">
                        <Button title={t("el.elem27")}  onClick={() => history.push(`/youthlab`)} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EcosystemContent;
