import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/styles.scss'
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import store from "./store/store"
import {Provider} from "react-redux";
import './i18n';
ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Provider store={store}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <App />
              </MuiPickersUtilsProvider>
          </Provider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
