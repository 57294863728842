import React from 'react';
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next"
const StartupContent = ({ data }) => {
    const history = useHistory();
    const { t } = useTranslation()
    return (
        <div className="startup-content">
            <div className="title">{t("el.elem16")}</div>
            {data.map(x => (
                     <div className="startup-content-item">
                     <div className="startup-content-item__left">
                         <div>
                             <span></span>
                             <div className="concurs">Конкурс</div>
                             <div className="name-courses">{x.name_ru}</div>
                             <div className="deadline">{t("el.elem45")}: {x.due_date}</div>
                         </div>
                         <button onClick={() => history.push('/startup/' + x.id)}>{t("el.elem2")}</button>
                     </div>
                     <div className="startup-content-item__right">
                         {x.due_date.split("-")[0]}
                     </div>
                 </div>
            ))}
        </div>
    );
};

export default StartupContent;
