
export const filteredCountry = (arr1, arr2) => {
    let newObj = {}
    let result = {}
    arr1.forEach(x => newObj[x.district] = x.district);
    const filtered =  arr2.filter(x => !newObj[x.district])
    const newArr = arr1.concat(filtered)
    newArr.forEach(x => result[x.district] = x)
    return result
}


export const fetchCardType = {
    1: 'create/startup/',
    2: 'create/investor/',
    3: 'create/mentor/'
}


export const checkUserType = {
    1: 'startup',
    2: 'investor',
    3: 'mentor'
}


const langObjStatus = {
    ru: {
        investor: "Инвестор",
        startup: "Стартапер",
        mentor: "Ментор"
    },
    en: {
        investor: "Investor",
        startup: "Startup",
        mentor: "Mentor"
    },
    kg: {
        investor: "Инвестор",
        startup: "Стартапер",
        mentor: "Ментор"
    },

}
const currentLang = localStorage.getItem('i18nextLng')
export const UserType = {
    1: langObjStatus[currentLang]?.startup,
    2: langObjStatus[currentLang]?.investor,
    3:  langObjStatus[currentLang]?.mentor,
    4: 'Пользователь рабочей зоны в ИЦ'
}


export const groupData = (arr) => {
    if(arr) {
        const reduce = arr.reduce((acc, cur) => {
            if (!acc[cur.type.name]) {
                acc[cur.type.name] = [cur]
            } else {
                acc[cur.type.name].push(cur)
            }
            return acc;
        }, {})
        return Object.keys(reduce).map(x => {
            return {
                title: x,
                count: reduce[x].length,
                data: reduce[x],
                color: reduce[x][0].type.color
            }
        })
    }
}
