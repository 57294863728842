import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import Logo from "../../Logo/Logo";
import Burger from "../../Header/Burger";
import Languages from "../../Header/Languages";
import Menu from "../../Header/Menu";
import {useHistory} from "react-router-dom";
import Overlay from "../../Overlay/Overlay";
import Modal from "../../Modal/Modal";
import Auth from "../../../pages/Auth/Auth";
import {ArrowDownIcon} from "../../icons/Icons";
import cn from "classnames";
import axios from "../../../axios";
import { useTranslation } from "react-i18next"
const HeaderContent = ({ children, bg }) => {
    const [open, setOpen] = useState(false)
    const { token } = useSelector(state => state.auth)
    const [modalOpen, setModalOpen] = useState(false)
    const [page_menu, setPageMenu] = useState([])
    const path = useHistory().location.pathname
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("i18nextLng"))
    const [isShowList, setIsShowList] = useState(false)
    const languages = ['ru', 'en', 'kg']
    const history = useHistory()
    const { t, i18n   } = useTranslation()
    const handleClick = () => {
        if(!token) {
            setModalOpen(true)
        } else {
            history.push('/profile')
        }
    }
    const fetch = async () => {
        const menu = await axios.get('/page_menu/');
        setPageMenu(menu.data)
    }
    useEffect(() => {
        fetch()
    }, [])

    return (
        <div className="header-content" style={{background: bg ?  `url(${bg})` : ''}}>
            <div className="header-content-wrap hidden-mobile">
                <div className="header-content__logo wow zoomIn" data-wow-duration="1s"  data-wow-delay="0.5s" data-wow-offset="10">
                    <Logo />
                </div>
                <div className="header-content__languages">
                    <Languages />
                </div>
                <div className="header-content__left">
                    <Burger onClick={() => setOpen(!open)} />
                    {path === '/' && <div className="auth-title" onClick={handleClick}>{token ? t("profile"): t("el.elem99")}</div>}
                </div>
            </div>
            <div className="header-top-mobile hidden-desktop">
                <div className="header-content__left">
                    <Burger onClick={() => setOpen(!open)} />
                    {path === '/' && <div className="auth-title" onClick={handleClick}>{token ? t("profile"): t("el.elem99")}</div>}
                </div>
                <div className="header-content__logo wow slideInLeft" data-wow-duration="1s"  data-wow-delay="1s" data-wow-offset="10">
                    <Logo />
                </div>
                <div className="header-content__languages">
                    <div className={cn("header-content__languages-selected", {
                        'active': isShowList
                    })}onClick={() => setIsShowList(!isShowList)}>
                        {selectedLang} <ArrowDownIcon />
                    </div>
                    {isShowList && <div className="header-content__languages-list">
                        {languages.filter(x => x !== selectedLang)
                            .map(x => <div className="header-content__languages-item"
                                           onClick={() => {
                                               setSelectedLang(x)
                                               i18n.changeLanguage(x)
                                               setIsShowList(false)
                                               window.location.reload()
                                           }}>{x}</div>)}
                    </div>}
                </div>
            </div>
            {children}
            <Overlay open={open} />
            <Menu open={open} onClick={() => setOpen(!open)} page_menu={page_menu} />
            <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
                <Auth />
            </Modal>
        </div>
    );
};

export default HeaderContent;
