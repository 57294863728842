import React, {useState,useEffect} from 'react';
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import img from "../../assets/ecosystem-bg.png";
import Button from "../../components/Button/Button";
import SliderComponents from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import 'react-calendar/dist/Calendar.css';
import AppointmentModal from "../../components/Modal/AppointmentModal";
import axios from "../../axios";
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

const Coworking = () => {
    const { t } = useTranslation()
    const [modalOpen, setModalOpen] = useState(false)
    const [form, setForm] = useState({
        fullName: "",
        email: "",
        phone: ""
    })
    const [data, setData] = useState({})
    const [booked, setBooked] = useState(null)
    const toggleModal = () => setModalOpen(!modalOpen)
    const handleChange = ({ target : {value, name} }) => setForm({...form, [name]: value})
    useEffect(() =>  {
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
            const { data } = await axios.get('/hall-reservation-list/')
            const result = await axios.get('/hall/2')
            setData(result.data)
            setBooked(data.map(x =>dayjs( x.date_start).format('DD.MM.YYYY')))
        } catch (e) {
            console.log(e.message)
        }
    }
    return (
        <div className="conference">
            <HeaderContent bg={data.main_image}>
                <div className="container container-sm">
                    <div className="title">{data.title}</div>
                    <p>{data.description}</p>
                    <Button title={t("el.elem28")} onClick={() => window.open('https://forms.gle/isZiPRrsLNZ1rMDVA')} />
                </div>
            </HeaderContent>
            <div className="conference__content p-100">
                <SliderComponents dinamyc={data.images} />
            </div>
            <Footer />
            <AppointmentModal
                coworking
                booked={booked}
                onChange={handleChange}
                onClose={toggleModal}
                open={modalOpen}
                form={form}
            />
        </div>
    );
};

export default Coworking;
