import React from 'react';
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next"
const EcosystemGalleryContent = ({ arr }) => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="ecosystem-gallery__content p-100">
            <h2>{t("el.elem13")}</h2>
            <div className="ecosystem-gallery__wrap">
                {arr.map(x => <div className="ecosystem-gallery__item" onClick={() => history.push(`/ecosystem/detail/${x.id}`)}>
                    <span> {x.name}</span>
                </div>)}
            </div>
        </div>
    );
};

export default EcosystemGalleryContent;
