import React, {memo} from 'react';
import {YMaps, Map, Placemark, ZoomControl} from 'react-yandex-maps';
import {SelectFieldComponent} from "../../components/TextField/TextFieldComponent";
import {useTranslation} from "react-i18next"
const GalleryMap = ({ mapData, firstActiveCategory, spheres, setActive, sphere,
                        setSphere }) => {
    const { t } = useTranslation()
    return (
        <div className="p-100">
            <div className="container container-sm" style={{
                marginBottom: "50px"
            }}>
                <h2>{t('el.elem15')}</h2>
                <div className="filter-sphere">
                    <SelectFieldComponent
                        name='startup'
                        title={t("el.elem49")}
                        elements={spheres}
                        value={sphere}
                        onChange={(e) => setSphere(e.target.value)}
                    />
                </div>
            </div>
            <div className="map-wrapper">
                <div className="category-filters">
                    {mapData.map(x => {
                        return (
                            <div className="category-item" onClick={() => setActive(x.data.map(x => ({...x.location[0], color: x.type.color, name: x.name})).flat())}>
                                <div className="category-item__color" style={{background: x.color}} />
                                <div className="category-item__name">{x.title}</div>
                                <div className="category-item__count">{x.data.length}</div>
                            </div>
                        )
                    })}
                </div>
                <YMaps>
                    <div>
                        <Map state={{  zoom: 12, center: [Number(firstActiveCategory[0].latitude), Number(firstActiveCategory[0].longitude)]  }} width="100%" height="500px" defaultState={{  zoom: 12, center: [Number(firstActiveCategory[0].latitude), Number(firstActiveCategory[0].longitude)]  }}>
                            {(firstActiveCategory || []).map(x => {
                                return (
                                    <Placemark
                                        geometry={[Number(x.latitude), Number(x.longitude)]}
                                        options={{   iconColor: x.color }}
                                        properties={{
                                            balloonContent: `<div>
                                                ${x.name}
                                                <br>
                                                ${x.address}
                                            </div>`
                                        }}
                                        modules={["geoObject.addon.balloon"]}
                                    />
                                )
                            })}
                            <ZoomControl options={{ float: 'right' }} />
                        </Map>
                    </div>
                </YMaps>
            </div>
        </div>
    );
};

export default memo(GalleryMap);
