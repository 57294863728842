import React from 'react';
import cn from "classnames";

const Overlay = ({ open }) => {
    return   <div className={cn("overlay", {
        'overlay-animation': open,
        'close-overlay': !open
    })} />
};

export default Overlay;
