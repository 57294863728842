import React, {useEffect, useState} from 'react';
import './StartupDetails.scss'
import axios from "../../axios";
import {useParams} from "react-router-dom";
import Burger from "../../components/Header/Burger";
import Logo from "../../components/Logo/Logo";
import Overlay from "../../components/Overlay/Overlay";
import Menu from "../../components/Header/Menu";
import Modal from "../../components/Modal/Modal";
import Auth from "../Auth/Auth";
import peopleCard from "../../assets/peopleCard.png"
import {useSelector} from "react-redux";
import StartupFeedback from "../../components/Modal/StartupFeedback";
import {UserType} from "../../helpers/helpers";
const StartupDetails = () => {
    const [startup, setStartup] = useState()
    const { id, details } = useParams()
    const [modalOpen, setModalOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [call, setCall] = useState(false)
    const { token } = useSelector(state => state.auth);
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const { data } = await axios.get(`/${details ? details:  'startup'}/${id}/`)
        setStartup(data)
    }
    const handleClickContact = () => {
        if(token) {
            setCall(true)
        }else {
            setModalOpen(true)
        }
    }
    if(!startup) return null
    console.log(startup);
    return (
            <div className="profile startup-detail">
                <div className="container container-sm">
                    <div className="profile-header">
                        <Burger onClick={() => setOpen(!open)} />
                        <Logo />
                    </div>
                    <div className="profile-top">
                        <div className="startup-detail__img">
                            <img src={startup?.user.image || peopleCard} alt=""/>
                        </div>
                        <div className="startup-detail__fullName">{startup?.user.first_name + ' ' +  startup?.user.last_name}</div>
                    </div>
                    <div className="startup-detail__info">
                        <div className="startup-detail__sphere">
                            {startup?.sphere.map(x => x.name)}
                        </div>
                        {startup?.idea && <div className="startup-detail__idea">
                            {startup?.idea}
                        </div>}
                        {startup?.description && <div className="startup-detail__idea">
                            {startup?.description}
                        </div>}
                        {startup?.purpose && <div style={{fontWeight: "bold"}} className="startup-detail__idea">
                            {startup?.purpose}
                        </div>}
                
                        {startup?.sum && <div className="startup-detail__price">Необходимая сумма: {startup?.sum}$</div>}
                        {startup?.file && <div className="profile-upload" style={{marginBottom:"15px", marginTop:"15px"}}>
                                <a style={{color: "#1c4a47"}} href={startup.file} target="_blank">Презентация</a>
                            </div>}
                        <div style={{marginTop: "20px"}}>{UserType[startup.user.user_type]}</div>
                        <button onClick={handleClickContact}>Связаться</button>
                    </div>
                </div>
                <Overlay open={open} />
                <Menu open={open} onClick={() => setOpen(!open)}  />
                <Modal width={true} open={modalOpen} onClose={() => setModalOpen(false)}>
                    <Auth onClose={() => setModalOpen(false)}  startupDetails={() => setCall(true)} />
                </Modal>
                <StartupFeedback
                    call={call}
                    setCall={setCall}
                    startup={startup}
                />
            </div>
    )
};

export default StartupDetails;
