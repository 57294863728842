import React, {useState} from 'react';
import {Redirect, useHistory, useParams} from "react-router-dom";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { useTranslation } from "react-i18next"
import axios from "axios";
import SuccessModal from "../../components/Modal/SuccessModal";
import {instanceWithoutApi} from "../../axios";
const ResetPassword = () => {
    const [form, setForm] = useState({
        password: '',
        password2: ''
    })
    const history = useHistory()
    const { uid, token } = useParams()
    const { t } = useTranslation()
    const handleChange = ({ target: {value, name} }) => setForm({...form, [name]: value})
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const validateForm = () => {
        const { password, password2 } = form;
        if(!password && password2) {
              setMessage("Заполните поля!")
            return false;
        }
        if(password !== password2) {
             setMessage("Пароли не совпадают")
            return false
        }
        return true
    }
    const handleSubmit = async () => {
        if(validateForm()) {
            try {
                await instanceWithoutApi.post('/auth/users/reset_password_confirm/', {
                    new_password: form.password,
                    uid,
                    token
                })
                setSuccess(true)
            } catch (e) {
                setMessage(e.response.data.new_password)
            }
        }
    }
    if(!uid) return <Redirect to='/' />
    return (
        <div className="auth-login reset-password">
           <div className="reset-password-wrap">
               <div className="auth-title">Сброс пароля</div>
               <TextFieldComponent
                   value={form.password}
                   onChange={handleChange}
                   title={t("el.elem80")}
                   name='password'
               />
               <TextFieldComponent
                   value={form.password2}
                   onChange={handleChange}
                   title={'Подтвердить пароль'}
                   name='password2'
               />
               {message && <div style={{color: "red", marginTop: "20px", marginBottom: "20px"}}>{message}</div>}
               <div className="auth-btn">
                   <button onClick={handleSubmit}>{t("el.elem99")}</button>
               </div>
               <SuccessModal setSuccess={setSuccess} success={success} title='Пароль успешно изменен' onClose={() => history.push('/') } />
           </div>
        </div>
    );
};

export default ResetPassword;
