import React from 'react';
import AdminTable1 from "./AdminTable1";
import AdminTable2 from "./AdminTable2";
import AdminTable3 from "./AdminTable3";
import AdminTable4 from "./AdminTable4";
import AdminTable5 from "./AdminTable5";

const AdminTable = ({ activeIndex, data }) => {
    const displayContent = () => {
        switch (activeIndex) {
            case 1:
                return <AdminTable1 data={data} />
            case 2:
                return <AdminTable2 data={data} />
            case 3:
                return <AdminTable3 data={data}/>
            case 4:
                return <AdminTable4 data={data} />
            case 5:
                return <AdminTable5 data={data} />
            default: return <div>Ничего не найдено</div>
        }
    }
    return (
        <div className="table-wrap">
            {displayContent()}
        </div>
    );
};

export default AdminTable;
