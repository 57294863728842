import React from 'react';
import {CloseIcon} from "../icons/Icons";
import cn from "classnames";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next"
import DropDown from "../DropDown/DropDown";
import { useDispatch , useSelector} from 'react-redux';
import { logout } from '../../store/auth/auth.actions';
const Menu = ({ open , onClick, page_menu }) => {
    const { t } =  useTranslation()
    const dispatch = useDispatch()
    const { token } = useSelector(state => state.auth)
    return (
        <div className={cn("menu-header", {
            'menu-open': open
        })}>
            <div className="menu-header__close" onClick={onClick}>
                <CloseIcon />
            </div>
            <ul className="menu-wrap">
                <li>
                    <Link to='/ecosystem'>{t("menu.menu1")}</Link>
                </li>

                <li>
                    <DropDown
                        item={t("menu.menu2")}
                        content={<ul>
                            <li>
                                <Link to='/center'>{t("el.elem6")}</Link>
                            </li>
                            <li>Коворкинг-зона</li>
                            <li>
                                <Link to='/conference'>Конференц-зал</Link>
                            </li>
                            <li>
                                <Link to='/fablab'>Fablab</Link>
                            </li>
                            <li>
                                <Link to='/youthlab'>Youthlab</Link>
                            </li>
                        </ul>}
                    />
                </li>
                <li>
                    {/* <Link to='/startup'>{t("menu.menu3")}</Link> */}
                </li>
                <li>
                    <Link to='/events'>{t("menu.menu4")}</Link>
                </li>
                <li>
                    <Link to="/portfolio">{t("menu.menu5")}</Link>
                </li>
                <li>
                    <DropDown
                        item={t("menu.menu6")}
                        content={  <ul>
                            <li>
                                <Link to='/lessonprogram'>Акселерация</Link>
                            </li>
                            <li>
                                <Link to='/lessonprogram'>Бизнес-инкубатор</Link>
                            </li>
                            <li>
                                <Link to='/lessonprogram'>Курсы по интеллектуальной собственнности</Link>
                            </li>
                            <li>
                                <Link to='/lessonprogram'>Steam образование</Link>
                            </li>
                        </ul>}
                    />
                </li>
                {page_menu.map((x, idx) => {
                     return (
                    <li>
                        <Link to={"/pages/"+x.slug}>{x.title}</Link>
                    </li>)
                })}
                {token && <li onClick={() => {
                    dispatch(logout())
                    onClick()
                }}>{t("temp.elem111")}</li>}
            </ul>
        </div>
    );
};

export default Menu;
