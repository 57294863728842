import React from 'react';
import SliderComponents from "../../components/Slider/Slider";
import modelPhoto from "../../assets/model.png"
 import { useTranslation } from "react-i18next"
const FablabContent = ({ onClick, fablab, equipments, images }) => {
    const { t } = useTranslation()
    return (
        <div className="fablab-content">
            {(equipments || []).length > 0 && <div className="fablab-technologies">
                <h2>Fablab</h2>
                <div className="subtitle">{t("el.elem36")}</div>
                <div className="fablab-technologies__wrap">
                    {(equipments || []).map(x => (
                        <div className="fablab-technologies__item">
                            <div className="fablab-technologies__item-bg" style={{background: `url(${x.image})`}}>
                                {x.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            <div className="fablab-content__education p-50">
                <div className="subtitle">{t("el.elem37")}</div>
                <SliderComponents getCourse={onClick} courses={fablab.courses} />
            </div>
            {fablab.aboniment_rules && <div className="fablab-content__abonements ">
                <div className="fablab-content__abonements-wrap">
                    <div className="fablab-content__abonements-left">
                        <div className="subtitle">{t("el.elem40")}</div>
                        <div className="subtitle-desc">Условия преобретения абонемента</div>
                        <p>{fablab.aboniment_rules}</p>
                    </div>
                    <div className="fablab-content__abonements-img">
                        <img className="img" src={modelPhoto} alt=""/>
                    </div>
                </div>
            </div>}
            <div className="fablab-content__education p-50">
                <div className="subtitle">{t("el.elem42")}</div>
                <SliderComponents dinamyc={images} />
            </div>
        </div>
    );
};

export default FablabContent;
