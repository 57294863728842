const initialState = {
    spheres: []
};




const dataReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'GET_SPHERES':
            return {
                ...state,
                spheres: action.payload
            }
        default:
            return state;
    }
}


export default dataReducer;