import { createStore, applyMiddleware,  compose } from 'redux';
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer  from "./rootReducer";

function generateCompose(...middlewares) {
    if (process.env.NODE_ENV === 'development') {
        return composeWithDevTools(...middlewares);
    }
    return compose(...middlewares);
}


const store = createStore(
    rootReducer,
    {},
    generateCompose(applyMiddleware(thunk))
);


export default store;
