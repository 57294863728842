import React from 'react';
import PeopleCard from "../../components/PeopleCard/PeopleCard";
import investor from "../../assets/investor.png"
import mentors from "../../assets/mentors.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {useHistory} from "react-router-dom";
import SwiperCore, { Navigation,   } from 'swiper';
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { useTranslation } from "react-i18next"
SwiperCore.use([Navigation]);
const PortfolioContent = ({ data, value,
                              setValue,
                              handleClickPerson }) => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="portfolio-content">
            <div className="portfolio__search">
                <input value={value} onChange={({ target: {value} }) => setValue(value)} type="text" placeholder="Название, имя" />
            </div>
            <div className="portfolio-startups">
                <div className="subtitle-bold">{t("temp.elem110")}</div>
                <div className="portfolio-startups__wrap hidden-mobile">
                    <Swiper slidesPerView={4} loop={true} spaceBetween={10} navigation   >
                        {data.startups.map(x => (
                            <SwiperSlide>
                                <PeopleCard
                                    link1={() => history.push(`/member/startup/${x.id}`)}
                                    link2={() => handleClickPerson(x)}
                                    img={x.user.image}
                                    title={x.title}
                                    userType={x.user.user_type}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="hidden-desktop">
                    <Swiper slidesPerView={1.2}  spaceBetween={30} navigation>
                        {data.startups.map(x => (
                            <SwiperSlide>
                                <PeopleCard
                                    link1={() => history.push(`/member/startup/${x.id}`)}
                                    link2={() => handleClickPerson(x)}
                                    img={x.user.image}
                                    title={x.title}
                                    userType={x.user.user_type}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="portfolio-investors">
                <div className="subtitle-bold">{t('el.elem20')}</div>
               <div className="portfolio-investors__wrap hidden-mobile">
                       <Swiper slidesPerView={4} loop={true} spaceBetween={30} navigation>
                           {data.investors.map(x => (
                               <SwiperSlide>
                                   <PeopleCard
                                       link1={() => history.push(`/member/investor/${x.id}`)}
                                       link2={() => handleClickPerson(x)}
                                       img={x.user.image}
                                       title={x.user.first_name + ' ' + x.user.last_name}
                                       userType={x.user.user_type}
                                   />
                               </SwiperSlide>
                           ))}
                       </Swiper>
               </div>
                <div className="hidden-desktop">
                    <Swiper slidesPerView={1.2}  spaceBetween={30} navigation>
                        {data.investors.map(x => (
                            <SwiperSlide>
                                <PeopleCard
                                    link1={() => history.push(`/member/investor/${x.id}`)}
                                    link2={() => handleClickPerson(x)}
                                    img={x.user.image}
                                    title={x.user.first_name + ' ' + x.user.last_name}
                                    userType={x.user.user_type}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="portfolio-mentors">
                <div className="subtitle-bold">{t("el.elem19")} </div>
                <div className="portfolio-mentors__wrap hidden-mobile">
                    <Swiper slidesPerView={4} loop={true} spaceBetween={30} navigation>
                        {data.investors.map(x => (
                            <SwiperSlide>
                                <PeopleCard
                                    link1={() => history.push(`/member/mentor/${x.id}`)}
                                    link2={() => handleClickPerson(x)}
                                    img={x.user.image}
                                    title={x.user.first_name + ' ' + x.user.last_name}
                                    userType={x.user.user_type}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="hidden-desktop"   >
                    <Swiper slidesPerView={1.2}  spaceBetween={30} navigation  >
                        {data.investors.map(x => (
                            <SwiperSlide>
                                <PeopleCard
                                    link1={() => history.push(`/member/mentor/${x.id}`)}
                                    link2={() => handleClickPerson(x)}
                                    img={x.user.image}
                                    title={x.user.first_name + ' ' + x.user.last_name}
                                    userType={x.user.user_type}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default PortfolioContent;
