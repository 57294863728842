import React, {useEffect, useState} from 'react';
import EventContent from "./EventContent";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import NewButton from "../../components/NewButton/NewButton";
import moder from "../../assets/moderator.png"
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import axios from "../../axios";
import Auth from "../Auth/Auth";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next"
import 'dayjs/locale/ru'
import dayjs from "dayjs";
import SuccessModal from "../../components/Modal/SuccessModal";
const Event = () => {
    const { token, user } = useSelector(state => state.auth)
    const [events, setEvents] = useState([]);
    const [openForm, setOpenForm] = useState(false)
    const [openAuth, setOpenAuth] = useState(false)
    const [value, setValue] = useState('')
    const [event, setEvent] = useState(null)
    const [sectionData, setSectionData] = useState({});
    const [success, setSuccess] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const { data } = await axios.get('/events/')
        const sectionData = await axios.get('/sections/4/')
        setSectionData(sectionData.data)
        setEvents(data)
    }

    const handleClickModal = () => {
        if(token) {
            setOpenForm(true)
        } else {
            setOpenAuth(true)
        }
    }
    const handleSendData = async (e) => {
        e.preventDefault()
        try {
            const obj = {
                user: user.id,
                event: event.id,
                comment: value
            }
            await axios.post('/event-registration/', obj)
            setSuccess(true)
        } catch (e) {

        }
    }
    return (
        <div className="event">
            <HeaderContent bg={sectionData.img}>
                <div className="container container-sm">
                    <div className="title">{sectionData.name}</div>
                    <p className="p">
                        {sectionData.description}
                     </p>
                </div>
            </HeaderContent>
            <div className="event-content">
                <div className="container container-sm">
                    <EventContent setEvent={setEvent} event={event} handleClickModal={handleClickModal} events={events}   />
                </div>
            </div>
            <Footer marginTop='100px' />
            {event && <Modal open={openForm} onClose={() => setOpenForm(false)}>
                <div className="event-modal">
                    <div className="event-modal-title">Зарегистрироваться на {event.event_type.name}</div>
                    <div className="event-modal-wrap">
                        <div className="event-modal-left">

                            <div className="event-modal-desc">Зарегистрируйтесь на данное мероприятие, чтобы <br/> Оставить заявку на бронирование место.</div>
                            <form>
                                <div className="textfield">
                                    <label htmlFor="comment">{t("el.elem21")}</label>
                                    <textarea name="comment" onChange={(e) => setValue(e.target.value)} value={value}  />
                                </div>
                                <div className="hidden-mobile">
                                    <NewButton title={t("el.elem43")} onClick={handleSendData}/>
                                </div>
                            </form>
                        </div>
                        <div className="event-modal-right">
                            <div className="event-modal-card">
                                <div className="event-modal-img">
                                    <img src={event.moderator.image || moder} alt=""/>
                                </div>
                                <span>Модератор</span>
                                <div className="event-modal-moder-name">{event.moderator.first_name + " " + event.moderator.last_name}</div>
                                <span>{event.event_type.name}</span>
                                <div className="event-modal-card-title">{event.description}</div>
                                <div className="event-date"><span>{t("el.elem23")}</span>: {dayjs(event.date).locale('ru').format('MMMM D, YYYY')}</div>
                                <div className="event-slot">
                                    <span>{t("el.elem22")}</span>: {event.address}
                                </div>
                            </div>
                        </div>
                        <div className="hidden-desktop">
                            <NewButton title={t("el.elem43")} onClick={handleSendData} />
                        </div>
                    </div>
                </div>
            </Modal>}
            <Modal width={true} open={openAuth} onClose={() => setOpenAuth(false)}>
                <Auth onClose={() => setOpenAuth(false)}  startupDetails={() => setOpenForm(true)} />
            </Modal>
            <SuccessModal setSuccess={setSuccess} success={success} onClose={() => setOpenForm(false)} />
        </div>
    );
};

export default Event;
