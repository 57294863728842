import React from 'react';

const PageDetailsContent = ({ data }) => {
    return (
        <div className="news-content">
            <div className="container container-sm">
                <div className="news-html"  dangerouslySetInnerHTML={{__html: data.body}} />
            </div>

        </div>
    );
};

export default PageDetailsContent;
