import axios from "../../axios";
export const fetchSectionProgram = () => {
    return axios.get('/sections/6').then(({ data }) => data)
}


export const fetchEducationalProgram = () => {
    return axios.get('/educational-programs/').then(({ data }) => data)
}


export const fetchEventPassed = (page = 1) => {
    return axios.get(`/events-steam-passed/?page=${page}`).then(({ data }) => data)
}



export const fetchEventComing = (page = 1) => {
    return axios.get(`/events-steam-coming/?page=${page}`).then(({ data }) => data)
}


export const fetchSteamBooks = () => {
    return axios.get('/steam-books/').then(({ data }) => data)
}
export const fetchSteamPartners = () => {
    return axios.get('/partners/').then(({ data }) => data)
}
export const fetchSteamDatabase = () => {
    return axios.get('/steam-databases/').then(({ data }) => data)
}
