import React from 'react';
import {useTranslation} from "react-i18next";
import cn from "classnames"
const Languages = () => {
    const { i18n } = useTranslation();
    const selectedLang = lang => () => {
        i18n.changeLanguage(lang)
        window.location.reload()
    }
    const arr = [
        'en',
        'ru',
        'kg'
    ]
    const activeLang = localStorage.getItem('i18nextLng')
    return (
        <ul className="header-sidebar-languages">
            {arr.map(x => <li onClick={selectedLang(x)} className={cn("", {
                'active': x === activeLang
            })}>{x}</li>)}
        </ul>
    );
};

export default Languages;
