import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import HeaderContent from "../../components/pages/MainPage/HeaderContent";
import axios from "../../axios";
import PageDetailsContent from "./PageDetailsContent";
import './NewsDetail.scss'
import Footer from "../../components/Footer/Footer"
const PageDetails = () => {
    const [data, setData] = useState(null)
    const { slug } = useParams()
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const { data } = await axios.get(`/page/${slug}/`)
        setData(data)
    }
    if(!data) return null
    return (
            <div className="news-page">
                <HeaderContent bg={data.img}>
                    <div className="container container-sm">
                        <div className="title">{data.title}</div>
                    </div>
                </HeaderContent>
                <PageDetailsContent data={data} />
                <Footer marginTop={100} />
            </div>
    );
};

export default PageDetails;
