import React, {useEffect} from 'react';
import {Animated} from "react-animated-css";

import img from "../../assets/bg.png"
import SliderComponents from "../../components/Slider/Slider";
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next"
const Content = ({ gallery, sections }) => {
    const history = useHistory()

    const { t } = useTranslation()
    const arr = [{
        title: t("menu.menu1"),
        path: '/ecosystem'
    },
        {
            title: t("menu.menu2"),
            path: '/center'
        },
        // {
        //     title: t("menu.menu3"),
        //     path: '/startup'
        // },
        {
            title: t("menu.menu4"),
            path: '/events'
        },
        {
            title: t("menu.menu5"),
            path: '/portfolio'
        },
        {
            title: t("menu.menu6"),
            path: '/lessonprogram'
        }
    ]
    return (
        <>
        <div className="main-page__content">
            {sections.sects.map((x, idx) => {
                if(idx % 2 + 1 === 1) {
                    return (
                        <div className="item variant-1">
                            <div className="container sm-container">
                                <div className="item-wrap">
                                    <div className="item-left wow slideInLeft">
                                        <div>{x.name}</div>
                                        <button className="btn-15 hidden-mobile" onClick={() => history.push('/'+x.slug)}>
                                            <span>{t("el.elem6")}</span>
                                            <span />
                                        </button>
                                    </div>
                                    <div className="item-right" data-wow-duration="1s"  data-wow-delay="1s" data-wow-offset="10">
                                        <img src={x.img} className=" wow zoomIn" alt=""/>
                                        <div className="item-right__blur"></div>
                                        <button className="hidden-desktop" onClick={() => history.push('/'+x.slug)}>
                                            <span>{t("el.elem6")}</span>
                                            <span />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="item variant-2">
                        <div className="container sm-container">
                            <div className="item-wrap">
                                <div className="item-right"  data-wow-delay="1s" data-wow-duration="2s" data-wow-offset="10">
                                    <img src={x.img} className="wow zoomIn" alt=""/>
                                    <div className="item-right__blur"></div>
                                    <button className="hidden-desktop" onClick={() => history.push('/'+x.slug)}>
                                        <span>{t("el.elem6")}</span>
                                        <span />
                                    </button>
                                </div>
                                <div className="item-left wow slideInRight">
                                    <div>{x.name}</div>
                                    <button className="hidden-mobile" onClick={() => history.push('/'+x.slug)}>
                                        <span>{t("el.elem6")}</span>
                                        <span />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <SliderComponents dinamyc={gallery} />
        </div>
            </>
    );
};

export default Content;
