import axios from "axios";
import store from "./store/store"
const instance = axios.create({
    baseURL: "http://ic.patent.kg/api/v1",
})

export const variableURL = 'http://ic.patent.kg/api/v1';
export const instanceWithoutApi = axios.create({
    baseURL: "http://ic.patent.kg"
})

instance.interceptors.request.use(
    config => {
        if(localStorage.getItem("token")) {
            config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }
        config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') === 'kg' ? 'ky' : localStorage.getItem('i18nextLng')
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)


export default instance;
