import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
const temp = {
    ru: {
        upload: 'Загрузить презентационный файл',
        name: "Наименование",
        startuper: "Стартапер",
        mentor: "Ментор",
        investor: "Инвестор",
        elem109: "Онлайн экскурсия",
        elem110: "Стартап идеи",
        elem111: "Выйти",
        elem112: "Вы инвестор?",
        elem113: "Описание",
        elem114: "Рассматриваем следующие сферы",
        elem115: "Вы Ментор?",
        elem116: "Информация о менторе",
        elem117: "Ваша учетная запись активирована",
        elem118: "Продолжить",
        elem119: "Сбросить",
        elem120: "Мы отправили ссылку для сброса пароля на вашу почту",
        elem121: "Пожалуйста, проверьте!",
    },
    en: {
        startuper: "Startup",
        mentor: "Mentor",
        investor: "Investor",
        upload: 'Upload a presentation file',
        name: "Name",
        status: "Investor",
        elem109: "Online tour",
        elem110: "Startup ideas",
        elem111: "Exit",
        elem112: "Are you investor?",
        elem113: "Description",
        elem114: "We consider the following areas",
        elem115: "Are you a Mentor?",
        elem116: "Information about Mentor",
        elem117: "Your account is activated",
        elem118: "Continue",
        elem119: "Change",
        elem120: "We have sent a link to reset your password to your email",
        elem121: "Pelase check!",
    },
    kg: {
        startuper: "Стартапер",
        mentor: "Ментор",
        investor: "Инвестор",
        upload: "Презентациялык файлды жүктөө",
        name: "Аты",
        status: "Investor",
        elem109: "Онлайн экскурсия",
        elem110: "Стартап идеялар",
        elem111: "Чыгуу",
        elem112: "Сиз Инвесторсузбу?",
        elem113: "Кенен маалымат",
        elem114: "Кийинки сфераларды карайбыз",
        elem115: "Сиз Менторсузбу?",
        elem116: "Ментор жөнүндө маалымат",
        elem117: "Сиздин аккаунтунуз активдештирилди",
        elem118: "Улантуу",
        elem119: "Өзгөртүү",
        elem120: "Биз сиздин электрондук почтаңызга сырсөзүңүздү баштапкы абалга келтирүү үчүн шилтеме жөнөттүк",
        elem121: "Почтаңызды текшериңиз!",
    }
}
const elements = {
    ru: {
        elem1: "Начало приёма заявок на StartUp Kyrgyzstan",
        elem2: "Подать заявку",
        elem3: "Профиль",
        elem4: "Все мероприятия",
        elem5: "Экосистема инноваций КР",
        elem6: "Перейти",
        elem7: "Инновационный центр",
        elem8: "StartUp Kyrgyzstan",
        elem9: "Мероприятия",
        elem10: "Портфолио участников экосистемы инноваций",
        elem11: "Обучающие программы",
        elem12: "Все права защищены",
        elem13: "Экосистемы",
        elem14: "Последние новости в области инновации",
        elem15: "Карта экосистемы инноваций",
        elem16: "Конкурсы",
        elem17: "Регистрация",
        elem18: "Стартаперы",
        elem19: "Менторы",
        elem20: "Инвесторы",
        elem21: "Примечание",
        elem22: "Место",
        elem23: "Когда",
        elem24: "Модератор",
        elem25: "Подробнее",
        elem26: "Связаться",
        elem27: "Узнать больше",
        elem28: "Оставить заявку на бронирование",
        elem29: "Проведенные мероприятия по STEAM образованию",
        elem30: "Предстоящие мероприятия по STEAM образованию",
        elem31: "STEAM Book",
        elem32: "Партнеры",
        elem33: "STEAM Database",
        elem34: "Сообщение успешно отправлено",
        elem35: "Спасибо, ваше сообщение успешно отправлено. Мы свяжемся с вами в ближайшее время.",
        elem36: "Оборудование",
        elem37: "Обучение",
        elem38: "Записаться на курс",
        elem39: "Забронируйте курсы, а мы перезвоним, проконсультируем и оформим заказ.",
        elem40: "Абонементы",
        elem41: "Условия приобретения абонемента",
        elem42: "Галерея",
        elem43: "Отправить",
        elem44: "Конкурсы",
        elem45: "Дедлайн",
        elem46: "Принять участие в конкурсе",
        elem47: "Примите участие в данном конкурсе и расскажите о своем стартапе.",
        elem48: "Выберите файл",
        elem49: "Сфера деятельности",
        elem50: "Количество поданных заявок",
        elem51: "Количество прошедших отборочный тур",
        elem52: "Количество прошедших в финал",
        elem53: "Количество финалистов",
        elem54: "Области",
        elem55: "Пол",
        elem56: "Женщины",
        elem57: "Мужчины",
        elem58: "Финалисты",
        elem59: "Нажимая на кнопку, я соглашаюсь на обработку персональных данных и с правилами пользования Платформы",
        elem60: "Заявки на регистрацию на мероприятия",
        elem61: "Заявки на регистрацию на обучающие курсы",
        elem62: "Заявки на связь с участником экосистемы",
        elem63: "Заявки на бронирование рабочих зон",
        elem64: "Заявки на участие в конкурсе StartUP Kyrgyzstan",
        elem65: "Мой профиль",
        elem66: "Редактировать",
        elem67: "Создайте персональную карточку",
        elem68: "Вы ментор? Разместите соответствующую карточку и станьте частью комьюнити!",
        elem69: "Создать карточку",
        elem70: "О себе",
        elem71: "Информация о менторе",
        elem72: "Сохранить изменения",
        elem73: "Редактировать профиль",
        elem74: "Фамилия",
        elem75: "Имя",
        elem76: "Контактный телефон",
        elem77: "Адрес",
        elem78: "Сфера деятельности",
        elem79: "Почему решили зарегистрироваться?",
        elem80: "Пароль",
        elem81: "Повторите пароль",
        elem82: "Выйти из аккаунта",
        elem83: "Создайте персональную карточку",
        elem84: "Вы инвестор? Разместите соответствующую карточку и станьте частью комьюнити!",
        elem85: "Создайте персональную карточку",
        elem86: "У вас стартап? Разместите соответствующую карточку и станьте частью комьюнити!",
        elem87: "Необходимая сумма",
        elem88: "Добавить новую",
        elem89: "Удалить",
        elem90: "Идея",
        elem91: "Ваши изменения сохранены",
        elem92: "Вы уверены, что хотите безвозвратно удалить?",
        elem93: "Да",
        elem94: "Назад",
        elem95: "Вход",
        elem96: "У меня нет аккаунта.",
        elem97: "Хочу зарегистрироваться",
        elem98: "Забыли пароль?",
        elem99: "Войти",
        elem100: "Продолжая, Вы принимаете условия и предложения StartUp Kyrgyzstan",
        elem101: "Введите адрес почты, на которую Вы получите ссылку для восстановления пароля",
        elem102: "Восстановить",
        elem103: "Мы отправили ссылку для сброса пароля на вашу почту. Пожалуйста, проверьте!",
        elem104: "У меня уже есть аккаунт.",
        elem105: "Хочу войти",
        elem106: "Мужской",
        elem107: "Женский",
        elem108: "Зарегистрироваться",
    },
    en: {
        elem1: "Start accepting applications for StartUp Kyrgyzstan",
        elem2: "Apply",
        elem3: "Profile",
        elem4: "All events",
        elem5: "Ecosystem of innovations of the KR",
        elem6: "Follow",
        elem7: "Innovation center",
        elem8: "StartUp Kyrgyzstan",
        elem9: "Events",
        elem10: "Portfolio of participants in the innovation ecosystem",
        elem11: "Educational programs",
        elem12: "All rights reserved",
        elem13: "Ecosystems",
        elem14: "Latest innovation news",
        elem15: "Innovation ecosystem map",
        elem16: "Contests",
        elem17: "Registration",
        elem18: "Startups",
        elem19: "Mentors",
        elem20: "Investors",
        elem21: "Comment",
        elem22: "Location",
        elem23: "When",
        elem24: "Moderator",
        elem25: "More",
        elem26: "Contact",
        elem27: "Learn more",
        elem28: "Book",
        elem29: "Conducted events on STEAM education",
        elem30: "Upcoming events on STEAM education",
        elem31: "STEAM Book",
        elem32: "Partners",
        elem33: "STEAM Database",
        elem34: "Message sent successfully",
        elem35: "Thank you, your message has been sent successfully. We will get in touch with you as soon as possible.",
        elem36: "Equipment",
        elem37: "Education",
        elem38: "Sign up for a course",
        elem39: "Sign up for a course, and we will call you back, advise and place an order.",
        elem40: "Subscriptions",
        elem41: "Conditions for purchasing a subscription",
        elem42: "Gallery",
        elem43: "Send",
        elem44: "Contests",
        elem45: "Deadline",
        elem46: "Take part in a contest",
        elem47: "Take part in this contest and tell us about your startup.",
        elem48: "Select a file",
        elem49: "Business sphere",
        elem50: "Number of submitted applications",
        elem51: "Number of qualified",
        elem52: "Number of applications in final",
        elem53: "Number of finalists",
        elem54: "Regions",
        elem55: "Gender",
        elem56: "Females",
        elem57: "Males",
        elem58: "Finalists",
        elem59: "By clicking on the button, I agree to the processing of personal data and the terms of use of the Platform",
        elem60: "Registration applications for events",
        elem61: "Registration applications for courses",
        elem62: "Applications for contact with an ecosystem participant",
        elem63: "Applications for booking work areas",
        elem64: "Applications for participation in the StartUP Kyrgyzstan contest",
        elem65: "My profile",
        elem66: "Edit",
        elem67: "Create a personalized  card",
        elem68: "Are you a mentor? Place the appropriate card and become part of the community!",
        elem69: "Create card",
        elem70: "About myself",
        elem71: "Information about the mentor",
        elem72: "Save changes",
        elem73: "Edit profile",
        elem74: "Last name",
        elem75: "First name",
        elem76: "Phone",
        elem77: "Address",
        elem78: "Business sphere",
        elem79: "Why did you decide to register?",
        elem80: "Password",
        elem81: "Repeat password",
        elem82: "Logout",
        elem83: "Create a personalized Investor card",
        elem84: "Are you an investor? Place the appropriate card and become part of the community!",
        elem85: "Create a personalized Startup card",
        elem86: "Do you have a startup? Place the appropriate card and become part of the community!",
        elem87: "Required amount",
        elem88: "Add new",
        elem89: "Delete",
        elem90: "Idea",
        elem91: "Your changes have been saved",
        elem92: "Are you sure you want to permanently delete it?",
        elem93: "Yes",
        elem94: "Back",
        elem95: "Login",
        elem96: "I don't have an account.",
        elem97: "I want to register",
        elem98: "Forgot your password?",
        elem99: "Login",
        elem100: "By continuing, you accept the terms and conditions of StartUp Kyrgyzstan",
        elem101: "Enter the email address to which you will receive a link to reset your password",
        elem102: "Restore",
        elem103: "We have sent a link to reset your password to your email. Please check!",
        elem104: "I already have an account.",
        elem105: "I want to login",
        elem106: "Male",
        elem107: "Female",
        elem108: "Register",
    },
    kg: {
        elem1: "StartUp Kyrgyzstan конкурсуна өтүнмөлөрдү кабыл алуу башталды",
        elem2: "Ɵтүнмө калтыруу",
        elem3: "Профиль",
        elem4: "Бардык иш-чаралар",
        elem5: "КР инновацияларынын экосистемасы",
        elem6: "Кирүү",
        elem7: "Инновация борбору",
        elem8: "StartUp Kyrgyzstan",
        elem9: "Иш-чаралар",
        elem10: "Инновациялык экосистеманын катышуучуларынын портфолиосу",
        elem11: "Билим берүү программалары",
        elem12: "Бардык укуктар корголгон",
        elem13: "Экосистемалар",
        elem14: "Акыркы инновация жаңылыктары",
        elem15: "Инновациялык экосистеманын картасы",
        elem16: "Сынактар",
        elem17: "Катталуу",
        elem18: "Стартаперлер",
        elem19: "Менторлор",
        elem20: "Инвесторлор",
        elem21: "Кошумча маалымат",
        elem22: "Өткөрүү жери",
        elem23: "Качан",
        elem24: "Модератор",
        elem25: "Кененирээк",
        elem26: "Байланышуу",
        elem27: "Кененирээк маалымат",
        elem28: "Ээлөө",
        elem29: "STEAM билим берүү боюнча өткөрүлгөн иш-чаралар",
        elem30: "Алдыдагы STEAM билим берүү боюнча өткөрүлүүчү иш-чаралары",
        elem31: "STEAM Book",
        elem32: "Партнерлор",
        elem33: "STEAM Database",
        elem34: "Билдирүү ийгиликтүү жөнөтүлдү",
        elem35: "Рахмат, билдирүүңүз ийгиликтүү жөнөтүлдү. Биз сиз менен жакынкы арада байланышабыз.",
        elem36: "Жабдуулар",
        elem37: "Билим берүү",
        elem38: "Курска жазылуу",
        elem39: "Курска катталыңыз, биз сизге кайра чалып, кеңеш беребиз.",
        elem40: "Абонементтер",
        elem41: "Абонементти алуу шарттары",
        elem42: "Галерея",
        elem43: "Жөнөтүү",
        elem44: "Конкурстар",
        elem45: "Мөөнөтү",
        elem46: "Конкурска катышуу",
        elem47: "Бул сынакка катышып, стартапыңыз тууралуу айтып бериңиз.",
        elem48: "Файлды тандаңыз",
        elem49: "Иш чөйрөсү",
        elem50: "Берилген өтүнмөлөрдүн саны",
        elem51: "Тандап алуу турунан өткөндөрдүн саны",
        elem52: "Финалга өткөндөрдүн саны",
        elem53: "Финалисттердин саны",
        elem54: "Областы",
        elem55: "Жынысы",
        elem56: "Аялдар",
        elem57: "Эркектер",
        elem58: "Финалисттер",
        elem59: "Баскычты басуу менен мен жеке маалыматтарды иштетүүгө жана Платформаны колдонуу шарттарына макулмун",
        elem60: "Иш-чарага жазылуу өтүнмөлөрү",
        elem61: "Курска жазылуу өтүнмөлөрү",
        elem62: "Экосистеманын катышуучусу менен байланышуу өтүнмөлөрү",
        elem63: "Жумуш ордун ээлөө өтүнмөлөрү",
        elem64: "StartUP Kyrgyzstan сынагына катышуу өтүнмөлөрү",
        elem65: "Менин профилим",
        elem66: "Өзгөртүү",
        elem67: "Өздүк Ментор картасын түзүңүз",
        elem68: "Сиз менторсузбу? Тиешелүү картаны толтуруп, коомчулуктун бир бөлүгү болуңуз!",
        elem69: "Картаны түзүү",
        elem70: "Өзүм жөнүндө",
        elem71: "Ментор жөнүндө маалымат",
        elem72: "Өзгөрүүлөрдү сактоо",
        elem73: "Профилди өзгөртүү",
        elem74: "Фамилия",
        elem75: "Аты",
        elem76: "Байланыш телефону",
        elem77: "Дареги",
        elem78: "Иш чөйрөсү",
        elem79: "Эмне үчүн каттоодон өтүүнү чечтиңиз?",
        elem80: "Пароль",
        elem81: "Парольду кайталаныз",
        elem82: "Аккаунттан чыгуу",
        elem83: "Өздүк Инвестор картасын түзүңүз",
        elem84: "Сиз инвесторсузбу? Тиешелүү картаны толтуруп, коомчулуктун бир бөлүгү болуңуз!",
        elem85: "Өздүк Стартапер картасын түзүңүз",
        elem86: "Сизде страртап идея барбы? Тиешелүү картаны толтуруп, коомчулуктун бир бөлүгү болуңуз!",
        elem87: "Керектүү сумма",
        elem88: "Жаңы кошуу",
        elem89: "Өчүрүү",
        elem90: "Идея",
        elem91: "Өзгөртүүлөрүңүз сакталды",
        elem92: "Чын эле биротоло өчүргүнүз келеби?",
        elem93: "Ооба",
        elem94: "Артка",
        elem95: "Кирүү",
        elem96: "Менде аккаунт жок.",
        elem97: "Мен катталгым келет",
        elem98: "Парольду унуттуңузбу?",
        elem99: "Кирүү",
        elem100: "Улантуу менен, сиз StartUp Kyrgyzstan шарттарын кабыл аласыз",
        elem101: "Парольду калыбына келтирүү үчүн шилтемени ала турган электрондук почта дарегин киргизиңиз",
        elem102: "Калыбына келтирүү",
        elem103: "Биз сиздин электрондук почтаңызга парольду калыбына келтирүү үчүн шилтеме жөнөттүк. Сураныч, текшериңиз!",
        elem104: "Менин аккаунтум бар.",
        elem105: "Мен киргим келет",
        elem106: "Эркек",
        elem107: "Аял",
        elem108: "Катталуу",
    }
}
const menu = {
    ru: {
        menu1: "Экосистема Инноваций Кыргызстана",
        menu2: "Инновационный центр",
        menu3: "StartUp Kyrgyzstan",
        menu4: "Мероприятия",
        menu5: "Портфолио участников экосистемы инноваций",
        menu6: "Обучающие программы",
    },
    en: {
        menu1: "Ecosystem of innovations of the KR",
        menu2: "Innovation center",
        menu3: "StartUp Kyrgyzstan",
        menu4: "Events",
        menu5: "Portfolio of participants in the innovation ecosystem",
        menu6: "Educational programs",
    },
    kg: {
        menu1: "КР инновацияларынын экосистемасы",
        menu2: "Инновация борбору",
        menu3: "StartUp Kyrgyzstan",
        menu4: "Иш-чаралар",
        menu5: "Инновациялык экосистеманын катышуучуларынын портфолиосу",
        menu6: "Билим берүү программалары",
    }
}
const resources = {
    en: {
        translation: {
            profile: "Profile",
            menu: {...menu.en},
            el: {...elements.en},
            temp: {...temp.en}
        }
    },
    ru: {
        translation: {
            profile: "Профиль",
            menu: {...menu.ru},
            el: {...elements.ru},
            temp: {...temp.ru}
        }
    },
    kg: {
        translation: {
            profile: "Профиль",
            menu: {...menu.kg},
            el: {...elements.kg},
            temp: {...temp.kg}
        }
    }
}

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n
